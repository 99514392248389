import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import corporateBanner from "../Asserts/websiteImages/corporate.webp";
import weddingBanner from "../Asserts/websiteImages/weddingEvents.webp";
import groupBanner from "../Asserts/websiteImages/groupBooking.webp";
// Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/navigation';

// import './styles.css';

// import required modules
import { Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
const HomeSwiper = () => {
  return (
    <>
<Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide>
        <div className="hero-single-slide" bis_skin_checked={1}>
            <div className="hero-highlighted-bg" bis_skin_checked={1}>
              <img src={corporateBanner} alt="" />
            </div>
            <div className="hero-content-bg" bis_skin_checked={1}>
              <div
                className="hero-content position-relative"
                bis_skin_checked={1}
              >
                <h2 className="hero-title">
                Corporate  <span>Events</span>
                </h2>
                <p>
                A premier corporate events organizer, our company specializes in curating seamless and impactful experiences for businesses. From meticulously planned conferences and seminars to engaging team-building activities, we leverage our expertise to ensure every detail is flawlessly executed. Elevate your corporate events with our dedicated and professional event management services.
                </p>
                <div className="hero-btns" bis_skin_checked={1}>
                <Link to="/About">
                <a
                    href="/About"
                    className="button-fill-primary"
                    bis_skin_checked={1}
                  >
                    View More
                  </a>
                </Link>
                  
                  <Link to="/Events">
                  <a
                    href="/Events"
                    className="button-outlined-primary"
                    bis_skin_checked={1}
                  >
                    Take A Tour
                  </a>
                  </Link>
                  
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="hero-single-slide" bis_skin_checked={1}>
            <div className="hero-highlighted-bg" bis_skin_checked={1}>
              <img src={weddingBanner} alt="" />
            </div>
            <div className="hero-content-bg" bis_skin_checked={1}>
              <div
                className="hero-content position-relative"
                bis_skin_checked={1}
              >
                <h2 className="hero-title">
                Wedding  <span>Organizer</span>
                </h2>
                <p>
                As a dedicated wedding organizer, we bring your dream celebration to life with meticulous planning and personalized touches. Our team ensures every detail, from venue selection to decor and coordination, reflects your unique love story. With a commitment to excellence, we transform your vision into a memorable and enchanting wedding experience.
                </p>
                <div className="hero-btns" bis_skin_checked={1}>
                <Link to="/About">
                <a
                    href="/About"
                    className="button-fill-primary"
                    bis_skin_checked={1}
                  >
                    View More
                  </a>
                </Link>
                  
                  <Link to="/Events">
                  <a
                    href="/Events"
                    className="button-outlined-primary"
                    bis_skin_checked={1}
                  >
                    Take A Tour
                  </a>
                  </Link>
                  
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="hero-single-slide" bis_skin_checked={1}>
            <div className="hero-highlighted-bg" bis_skin_checked={1}>
              <img src={groupBanner} alt="" />
            </div>
            <div className="hero-content-bg" bis_skin_checked={1}>
              <div
                className="hero-content position-relative"
                bis_skin_checked={1}
              >
                <h2 className="hero-title">
                Group  <span>Booking</span>
                </h2>
                <p>
                Facilitating seamless group bookings, our services cater to a variety of travel needs. Whether organizing a corporate retreat or planning a group vacation, we specialize in creating tailored solutions. Our dedicated team ensures a smooth process, offering personalized assistance to meet the unique requirements of each group, ensuring a hassle-free and enjoyable experience.

                </p>
                <div className="hero-btns" bis_skin_checked={1}>
                <Link to="/About">
                <a
                    href="/About"
                    className="button-fill-primary"
                    bis_skin_checked={1}
                  >
                    View More
                  </a>
                </Link>
                  
                  <Link to="/Events">
                  <a
                    href="/Events"
                    className="button-outlined-primary"
                    bis_skin_checked={1}
                  >
                    Take A Tour
                  </a>
                  </Link>
                  
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  )
}

export default HomeSwiper