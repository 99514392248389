import React from 'react'
import serviceCorporate from "../Asserts/websiteImages/corporateServices.jpg";
import serviceWedding from "../Asserts/websiteImages/Church-Wedding-Ceremony.webp";
import serviceGrounp from "../Asserts/websiteImages/goa-group-bookings.webp";

const EventsOrganizer = () => {
  return (
    <>
         <section id="whatWeDid" className=" contentRowPad mt-5">
        <div className="container" bis_skin_checked={1}>
          <div className="row justify-content-center" bis_skin_checked={1}>
          <div className="col-lg-8" bis_skin_checked={1}>
        <div
          className="section-head-alpha section-padding-15 text-center mx-auto"
          bis_skin_checked={1}
        >
          <h2> Events</h2>
          <p>
          Our events are meticulously planned, offering unforgettable experiences filled with excitement, laughter, and cherished memories. Join us for remarkable occasions!
          </p>
        </div>
      </div>
          </div>
          <div className="row" bis_skin_checked={1}>
            <div className="col-md-6" id="gokulhall" bis_skin_checked={1}>
              <div className="banqImg" bis_skin_checked={1}>
                <img
                  className="events-img"
                  src={serviceCorporate}
                  alt="banquet halls"
                />
              </div>
            </div>
            <div className="col-md-6 tab-contents" bis_skin_checked={1}>
              <div className="tab-content p-3" bis_skin_checked={1}>
                <div
                  role="tabpanel"
                  className="tab-pane active"
                  bis_skin_checked={1}
                >
                  {/* <h4>Ground Floor</h4> */}
                  <h3 className="my-4">Corporate Event Organiser</h3>
                  <p>
                    As a premier corporate event organizer, we specialize in
                    curating seamless and impactful experiences for businesses.
                    From meticulously planned conferences and seminars to
                    engaging team-building activities, our dedicated team
                    ensures every detail is flawlessly executed. Elevate your
                    corporate events with our professional event management
                    services, creating lasting impressions for your
                    organization.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6  tab-contents"
              id="saraswati-auditorium"
              bis_skin_checked={1}
            >
              <div className="tab-content p-3" bis_skin_checked={1}>
                <div
                  role="tabpanel"
                  className="tab-pane active"
                  id="wwd2018"
                  bis_skin_checked={1}
                >
                  {/* <h4>Second Floor</h4> */}
                  <h3 className="my-4">Ceremonies Organiser</h3>
                  <p>
                    As a dedicated ceremonies organizer, we bring your special
                    moments to life with meticulous planning and personalized
                    touches. From weddings and anniversaries to cultural
                    celebrations, our experienced team ensures each detail
                    reflects your unique vision. Entrust us to turn your
                    ceremonies into unforgettable and seamless experiences,
                    filled with joy and significance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 " bis_skin_checked={1}>
              <div className="banqImg" bis_skin_checked={1}>
                <img
                  className="events-img"
                  src={serviceWedding}
                  alt="banquet halls"
                />
              </div>
            </div>
            <div
              className="col-md-6"
              id="janardhan-ac-hall"
              bis_skin_checked={1}
            >
              <div className="banqImg" bis_skin_checked={1}>
                <img
                  className="events-img"
                  src={serviceGrounp}
                  alt="banquet halls"
                />
              </div>
            </div>
            <div className="col-md-6 tab-contents" bis_skin_checked={1}>
              <div className="tab-content p-3" bis_skin_checked={1}>
                <div
                  role="tabpanel"
                  className="tab-pane active"
                  id="wwd2018"
                  bis_skin_checked={1}
                >
                  {/* <h4>Fourth Floor</h4> */}
                  <h3 className="my-4">Group Booking</h3>
                  <p>
                    Facilitating seamless group bookings, our services cater to
                    various travel needs. Whether organizing a corporate retreat
                    or planning a group vacation, we specialize in creating
                    tailored solutions. Our dedicated team ensures a smooth
                    process, offering personalized assistance to meet the unique
                    requirements of each group, ensuring a hassle-free and
                    enjoyable experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default EventsOrganizer