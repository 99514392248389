import React from 'react'
import { Link } from 'react-router-dom'
import Logo from "../Asserts/2-removebg-preview.png";
const ErrorPage = () => {
  return (
    <>
        <div className="error-wrapper pt-100" bis_skin_checked={1}>
  <div className="container" bis_skin_checked={1}>
    <div className="row justify-content-center" bis_skin_checked={1}>
      <div className="col-lg-8" bis_skin_checked={1}>
        <div className="error-content text-center" bis_skin_checked={1}>
          <div className="error-vactor text-center" bis_skin_checked={1}>
            <img
              src={Logo}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="error-text" bis_skin_checked={1}>
          <h2>404</h2>
            <h2>That Page Are Not Found</h2>

            <div className="error-btn mb-5" bis_skin_checked={1}>
             <Link to="/">
             <a href="/">
                <i className="bi bi-house-door" /> GO TO HOME
              </a>
             </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default ErrorPage