import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import { RiDoubleQuotesL } from "react-icons/ri";


const TestimonialSlider = () => {
  return (
    <>
        <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
      {/* {serviceData.map((v,i)=>{ */}
            <SwiperSlide>
            <div className="testimonial-card testimonial-card-alpha" bis_skin_checked={1}>
  <div className="testimonial-overlay-img" bis_skin_checked={1}>
    {/* <img src="assets/images/reviewer/reviewer-1.png" alt="" /> */}
  </div>
  <div className="testimonial-card-top" bis_skin_checked={1}>
    <div className="qoute-icon" bis_skin_checked={1}>
      <i className="bx bxs-quote-left" />
    </div>
    <div className="testimonial-thumb" bis_skin_checked={1}>
      <img src="https://www.w3schools.com/w3css/img_avatar3.png" alt="" />
    </div>
    <h3 className="testimonial-count">01</h3>
  </div>
  <div className="testimonial-body" bis_skin_checked={1}>
    <p>
    This resort exceeded all expectations! From luxurious amenities to exceptional service, every moment was truly unforgettable. Highly recommended!
    </p>
    <div className="testimonial-bottom" bis_skin_checked={1}>
      <div className="reviewer-info" bis_skin_checked={1}>
        <h4 className="reviewer-name">Pooja Sharma</h4>
        <h6>Traveller</h6>
      </div>
      <ul className="testimonial-rating">
        <li>
          <i className="bi bi-star-fill" />
        </li>
        <li>
          <i className="bi bi-star-fill" />
        </li>
        <li>
          <i className="bi bi-star-fill" />
        </li>
        <li>
          <i className="bi bi-star-fill" />
        </li>
        <li>
          <i className="bi bi-star-fill" />
        </li>
      </ul>
    </div>
  </div>
</div>
            </SwiperSlide>
           
            <SwiperSlide>
            <div className="testimonial-card testimonial-card-alpha" bis_skin_checked={1}>
  <div className="testimonial-overlay-img" bis_skin_checked={1}>
    {/* <img src="assets/images/reviewer/reviewer-1.png" alt="" /> */}
  </div>
  <div className="testimonial-card-top" bis_skin_checked={1}>
    <div className="qoute-icon" bis_skin_checked={1}>
      <i className="bx bxs-quote-left" />
    </div>
    <div className="testimonial-thumb" bis_skin_checked={1}>
      <img src="https://www.w3schools.com/w3css/img_avatar3.png" alt="" />
    </div>
    <h3 className="testimonial-count">02</h3>
  </div>
  <div className="testimonial-body" bis_skin_checked={1}>
    <p>
    This villa surpassed all expectations! Stunning views, luxurious amenities, and impeccable service made our stay absolutely perfect. Highly recommended
    </p>
    <div className="testimonial-bottom" bis_skin_checked={1}>
      <div className="reviewer-info" bis_skin_checked={1}>
        <h4 className="reviewer-name">Aayush Jain</h4>
        <h6>Traveller</h6>
      </div>
      <ul className="testimonial-rating">
        <li>
          <i className="bi bi-star-fill" />
        </li>
        <li>
          <i className="bi bi-star-fill" />
        </li>
        <li>
          <i className="bi bi-star-fill" />
        </li>
        <li>
          <i className="bi bi-star-fill" />
        </li>
        <li>
          <i className="bi bi-star-fill" />
        </li>
      </ul>
    </div>
  </div>
</div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="testimonial-card testimonial-card-alpha" bis_skin_checked={1}>
  <div className="testimonial-overlay-img" bis_skin_checked={1}>
    {/* <img src="assets/images/reviewer/reviewer-1.png" alt="" /> */}
  </div>
  <div className="testimonial-card-top" bis_skin_checked={1}>
    <div className="qoute-icon" bis_skin_checked={1}>
      <i className="bx bxs-quote-left" />
    </div>
    <div className="testimonial-thumb" bis_skin_checked={1}>
      <img src="https://www.w3schools.com/w3css/img_avatar3.png" alt="" />
    </div>
    <h3 className="testimonial-count">03</h3>
  </div>
  <div className="testimonial-body" bis_skin_checked={1}>
    <p>
    This hotel exceeded our expectations! Impeccable service, comfortable rooms, and delicious food made our stay truly memorable. Highly recommend it!
    </p>
    <div className="testimonial-bottom" bis_skin_checked={1}>
      <div className="reviewer-info" bis_skin_checked={1}>
        <h4 className="reviewer-name">Sagar Madhekar</h4>
        <h6>Traveller</h6>
      </div>
      <ul className="testimonial-rating">
        <li>
          <i className="bi bi-star-fill" />
        </li>
        <li>
          <i className="bi bi-star-fill" />
        </li>
        <li>
          <i className="bi bi-star-fill" />
        </li>
        <li>
          <i className="bi bi-star-fill" />
        </li>
        <li>
          <i className="bi bi-star-fill" />
        </li>
      </ul>
    </div>
  </div>
</div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="testimonial-card testimonial-card-alpha" bis_skin_checked={1}>
  <div className="testimonial-overlay-img" bis_skin_checked={1}>
    {/* <img src="assets/images/reviewer/reviewer-1.png" alt="" /> */}
  </div>
  <div className="testimonial-card-top" bis_skin_checked={1}>
    <div className="qoute-icon" bis_skin_checked={1}>
      <i className="bx bxs-quote-left" />
    </div>
    <div className="testimonial-thumb" bis_skin_checked={1}>
      <img src="https://www.w3schools.com/w3css/img_avatar3.png" alt="" />
    </div>
    <h3 className="testimonial-count">04</h3>
  </div>
  <div className="testimonial-body" bis_skin_checked={1}>
    <p>
    The banquet at this venue was exceptional! Delicious food, elegant ambiance, and attentive staff made our event truly memorable."
    </p>
    <div className="testimonial-bottom" bis_skin_checked={1}>
      <div className="reviewer-info" bis_skin_checked={1}>
        <h4 className="reviewer-name">Rahul Chate</h4>
        <h6>Traveller</h6>
      </div>
      <ul className="testimonial-rating">
        <li>
          <i className="bi bi-star-fill" />
        </li>
        <li>
          <i className="bi bi-star-fill" />
        </li>
        <li>
          <i className="bi bi-star-fill" />
        </li>
        <li>
          <i className="bi bi-star-fill" />
        </li>
        <li>
          <i className="bi bi-star-fill" />
        </li>
      </ul>
    </div>
  </div>
</div>
            </SwiperSlide>
      </Swiper>
    </>
  )
}

export default TestimonialSlider