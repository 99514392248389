import React from 'react'
import EventsOrganizer from './EventsOrganizer'

const Event = () => {
  return (
  <>
<div className="breadcrumb breadcrumb-style-one"  bis_skin_checked={1}>
  <div className="container" bis_skin_checked={1}>
    <div className="col-lg-12 text-center" bis_skin_checked={1}>
      <h2 className="breadcrumb-title">Our Events</h2>
      <ul className="d-flex justify-content-center breadcrumb-items">
        <li className="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        <li className="breadcrumb-item active">Our Events</li>
      </ul>
    </div>
  </div>
</div>
<section>
  {/* Services Start */}
<EventsOrganizer />

{/* services end */}
</section>
  </>
  )
}

export default Event