import React from "react";
import TestimonialSlider from "../Pages/TestimonialSlider";
import wdevent5 from "../Asserts/weedingEvents/WeddingEvents(5).webp";
import villa4 from "../Asserts/villa/TRVLBV01/TRVLBV01 (4).webp";
import aboutUs1 from "../Asserts/websiteImages/1.webp";
import aboutUs from "../Asserts/villa/TRVLBV03/TRVLBV03(22).webp";
import { TiWorld } from "react-icons/ti";
import { MdOutlineEventAvailable } from "react-icons/md";
import { FaAward } from "react-icons/fa";
import { FaMap } from "react-icons/fa";
import Counter from "../Pages/Counter";
import sanjeevniKharve from "../Asserts/SanjeevniKharve.webp";
import sandhyaSigh from "../Asserts/SandhyaSingh.webp";

const About = () => {
  return (
    <>
      <div className="breadcrumb breadcrumb-style-one" bis_skin_checked={1}>
        <div className="container" bis_skin_checked={1}>
          <div className="col-lg-12 text-center" bis_skin_checked={1}>
            <h2 className="breadcrumb-title">About Us</h2>
            <ul className="d-flex justify-content-center breadcrumb-items">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active">About Us</li>
            </ul>
          </div>
        </div>
      </div>
      {/* About us Start */}
      <div className="about-main-wrappper pt-100" bis_skin_checked={1}>
        <div className="container" bis_skin_checked={1}>
          <div className="about-tab-wrapper" bis_skin_checked={1}>
            <div
              className="row justify-content-center align-items-center"
              bis_skin_checked={1}
            >
              <div className="col-lg-6" bis_skin_checked={1}>
                <div
                  className="about-tab-image-grid text-center"
                  bis_skin_checked={1}
                >
                  <div
                    className="about-video aboutUs-Img d-inline-block mb-5"
                    bis_skin_checked={1}
                  >
                    <img src={aboutUs} alt="" />
                    <div className="video-overlay" bis_skin_checked={1}></div>
                  </div>
                  <div className="row float-images g-4" bis_skin_checked={1}>
                    <div
                      className="col-lg-6 col-md-6 col-sm-6"
                      bis_skin_checked={1}
                    >
                      <div className="about-image" bis_skin_checked={1}>
                        <img src={wdevent5} alt="" />
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-6"
                      bis_skin_checked={1}
                    >
                      <div className="about-image" bis_skin_checked={1}>
                        <img src={aboutUs1} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-5 mt-lg-0" bis_skin_checked={1}>
                <div className="about-tab-wrap" bis_skin_checked={1}>
                  <h2 className="about-wrap-title">
                    About Our Company And <span>What We Are</span> Offer.
                  </h2>
                  <div className="about-tab-switcher" bis_skin_checked={1}>
                    <ul
                      className="nav nav-pills mb-3 justify-content-md-between justify-content-center"
                      id="about-tab-pills"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link active"
                          id="pills-about1"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills1"
                          role="tab"
                          aria-controls="about-pills1"
                          aria-selected="true"
                          bis_skin_checked={1}
                        >
                          <h3>5</h3>
                          <h6>Years Experience</h6>
                        </div>
                      </li>
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link"
                          id="pills-about2"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills2"
                          role="tab"
                          aria-controls="about-pills2"
                          aria-selected="false"
                          bis_skin_checked={1}
                        >
                          <h3>40+</h3>
                          <h6>Tour Guide</h6>
                        </div>
                      </li>
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link"
                          id="pills-about3"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills3"
                          role="tab"
                          aria-controls="about-pills3"
                          aria-selected="false"
                          bis_skin_checked={1}
                        >
                          <h3>50+</h3>
                          <h6>Travelar Connect</h6>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="tab-content about-tab-content"
                    id="pills-tabContent"
                    bis_skin_checked={1}
                  >
                    <div
                      className="tab-pane fade show active"
                      id="about-pills1"
                      role="tabpanel"
                      aria-labelledby="pills-about1"
                      bis_skin_checked={1}
                    >
                      <p>
                        Explore the ethos of our company and the extensive array
                        of offerings we proudly present. With a diverse
                        portfolio encompassing numerous resorts, villas, banquet
                        facilities, and hotels, we cater to a broad spectrum of
                        preferences. Our commitment to excellence extends to
                        crafting unforgettable experiences, ensuring every
                        resort stay, villa retreat, banquet event, and hotel
                        accommodation exceeds expectations. Immerse yourself in
                        the exceptional, as we continue to set new standards in
                        hospitality, offering unparalleled variety and quality
                        across our distinctive properties.
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="about-pills2"
                      role="tabpanel"
                      aria-labelledby="pills-about2"
                      bis_skin_checked={1}
                    >
                      <p>
                        {" "}
                        Explore the ethos of our company and the extensive array
                        of offerings we proudly present. With a diverse
                        portfolio encompassing numerous resorts, villas, banquet
                        facilities, and hotels, we cater to a broad spectrum of
                        preferences. Our commitment to excellence extends to
                        crafting unforgettable experiences, ensuring every
                        resort stay, villa retreat, banquet event, and hotel
                        accommodation exceeds expectations. Immerse yourself in
                        the exceptional, as we continue to set new standards in
                        hospitality, offering unparalleled variety and quality
                        across our distinctive properties.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About us End */}
      {/* owner and manager content start */}
      <div className="about-main-wrappper pt-100" bis_skin_checked={1}>
        <div className="container" bis_skin_checked={1}>
          <div className="about-tab-wrapper" bis_skin_checked={1}>
            <div
              className="row justify-content-center align-items-center"
              bis_skin_checked={1}
            >
              {/* <div className="col-lg-6" bis_skin_checked={1}>
                <div
                  className="about-tab-image-grid text-center"
                  bis_skin_checked={1}
                >
                  <div
                    className="about-video aboutUs-Img d-inline-block mb-5"
                    bis_skin_checked={1}
                  >
                    <img src={aboutUs} alt="" />
                    <div className="video-overlay" bis_skin_checked={1}></div>
                  </div>
                  <div className="row float-images g-4" bis_skin_checked={1}>
                    <div
                      className="col-lg-6 col-md-6 col-sm-6"
                      bis_skin_checked={1}
                    >
                      <div className="about-image" bis_skin_checked={1}>
                        <img src={wdevent5} alt="" />
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-6"
                      bis_skin_checked={1}
                    >
                      <div className="about-image" bis_skin_checked={1}>
                        <img src={aboutUs1} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <img src={sandhyaSigh} style={{ width: "100%",borderRadius:"10px" }} />
              </div> */}
              <div className="col-lg-12 mt-5 mt-lg-0" bis_skin_checked={1}>
                <div className="about-tab-wrap manager-about-us-sec" bis_skin_checked={1}>
                  <img src={sanjeevniKharve} style={{width:"25vh",height:"25vh", borderRadius:"50%"}} />
                  {/* <h2 className="about-wrap-title">
                    About Our Company And <span>What We Are</span> Offer.
                  </h2> */}
                  {/* <div className="about-tab-switcher" bis_skin_checked={1}>
                    <ul
                      className="nav nav-pills mb-3 justify-content-md-between justify-content-center"
                      id="about-tab-pills"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link active"
                          id="pills-about1"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills1"
                          role="tab"
                          aria-controls="about-pills1"
                          aria-selected="true"
                          bis_skin_checked={1}
                        >
                          <h3>5</h3>
                          <h6>Years Experience</h6>
                        </div>
                      </li>
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link"
                          id="pills-about2"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills2"
                          role="tab"
                          aria-controls="about-pills2"
                          aria-selected="false"
                          bis_skin_checked={1}
                        >
                          <h3>40+</h3>
                          <h6>Tour Guide</h6>
                        </div>
                      </li>
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link"
                          id="pills-about3"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills3"
                          role="tab"
                          aria-controls="about-pills3"
                          aria-selected="false"
                          bis_skin_checked={1}
                        >
                          <h3>50+</h3>
                          <h6>Travelar Connect</h6>
                        </div>
                      </li>
                    </ul>
                  </div> */}
                  <div
                    className="tab-content about-tab-content"
                    id="pills-tabContent"
                    bis_skin_checked={1}
                  >
                    <div
                      className="tab-pane fade show active"
                      id="about-pills1"
                      role="tabpanel"
                      aria-labelledby="pills-about1"
                      bis_skin_checked={1}
                    >
                      <p>
                        Sanjeevni Kharve is a seasoned manager at Travel Beez,
                        adept at orchestrating a diverse array of events ranging
                        from corporate meetings and conferences to extravagant
                        product launches and grand-scale weddings. With her
                        extensive experience, she navigates the complexities of
                        event planning with finesse, ensuring seamless execution
                        and client satisfaction. From meticulously arranging
                        office setups to orchestrating board meetings and
                        managing superior accommodations, Sanjeevni is dedicated
                        to delivering excellence in every aspect of her work.
                        Her tenure with Travel Beez has been marked by a
                        commitment to professionalism and a passion for creating
                        unforgettable experiences for clients, making her an
                        invaluable asset to the team.
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="about-pills2"
                      role="tabpanel"
                      aria-labelledby="pills-about2"
                      bis_skin_checked={1}
                    >
                      <p>
                        {" "}
                        Explore the ethos of our company and the extensive array
                        of offerings we proudly present. With a diverse
                        portfolio encompassing numerous resorts, villas, banquet
                        facilities, and hotels, we cater to a broad spectrum of
                        preferences. Our commitment to excellence extends to
                        crafting unforgettable experiences, ensuring every
                        resort stay, villa retreat, banquet event, and hotel
                        accommodation exceeds expectations. Immerse yourself in
                        the exceptional, as we continue to set new standards in
                        hospitality, offering unparalleled variety and quality
                        across our distinctive properties.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* owner and manager content end */}
      {/* counter start */}
      <Counter />
      {/* counter end */}
      <div className="about-main-wrappper pt-100" bis_skin_checked={1}>
        <div className="container" bis_skin_checked={1}>
          <div className="about-tab-wrapper" bis_skin_checked={1}>
            <div
              className="row justify-content-center align-items-center"
              bis_skin_checked={1}
            >
              <div className="col-lg-12 sandhya-sec" bis_skin_checked={1}>
                {/* <div
                  className="about-tab-image-grid text-center"
                  bis_skin_checked={1}
                >
                  <div
                    className="about-video aboutUs-Img d-inline-block mb-5"
                    bis_skin_checked={1}
                  >
                    <img src={aboutUs} alt="" />
                    <div className="video-overlay" bis_skin_checked={1}></div>
                  </div>
                  <div className="row float-images g-4" bis_skin_checked={1}>
                    <div
                      className="col-lg-6 col-md-6 col-sm-6"
                      bis_skin_checked={1}
                    >
                      <div className="about-image" bis_skin_checked={1}>
                        <img src={wdevent5} alt="" />
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-6"
                      bis_skin_checked={1}
                    >
                      <div className="about-image" bis_skin_checked={1}>
                        <img src={aboutUs1} alt="" />
                      </div>
                    </div>
                  </div>
                </div> */}
                <img src={sandhyaSigh} className="sndhya-poster"  />
              </div>
              
            </div>
          </div>
        </div>
      </div>
      {/* Testimonial Start */}
      <div
        className="testimonial-area testimonial-style-one "
        bis_skin_checked={1}
      >
        <div className="testimonial-shape-group" bis_skin_checked={1} />
        <div className="container position-relative" bis_skin_checked={1}>
          <div className="row align-items-center" bis_skin_checked={1}>
            <div className="col-lg-8" bis_skin_checked={1}>
              <div className="section-head-alpha " bis_skin_checked={1}>
                <h2>What Our Client Say About Us</h2>
                <p>
                  Duis rutrum nisl urna. Maecenas vel libero faucibus nisi
                  venenatis hendrerit a id lectus. Suspendissendt blandit
                  interdum. Sed pellentesque at nunc eget consectetur.
                </p>
              </div>
            </div>
            {/* <div className="col-lg-4" bis_skin_checked={1}>
        <div
          className="slider-arrows text-center d-lg-flex d-none justify-content-end mb-3"
          bis_skin_checked={1}
        >
          <div
            className="testi-prev custom-swiper-prev"
            tabIndex={0}
            role="button"
            aria-label="Previous slide"
            aria-controls="swiper-wrapper-10e2f2d6974baf508"
            bis_skin_checked={1}
          >
            {" "}
            <i className="bi bi-chevron-left" />{" "}
          </div>
          <div
            className="testi-next custom-swiper-next"
            tabIndex={0}
            role="button"
            aria-label="Next slide"
            aria-controls="swiper-wrapper-10e2f2d6974baf508"
            bis_skin_checked={1}
          >
            <i className="bi bi-chevron-right" />
          </div>
        </div>
      </div> */}
          </div>
          <div>
            <TestimonialSlider />
          </div>
        </div>
      </div>

      {/* Testimonial End */}
    </>
  );
};

export default About;
