import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { TiEyeOutline } from "react-icons/ti";


const GalleyCard = ({ images }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [visibleImages, setVisibleImages] = useState(8);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const loadMoreImages = () => {
    setVisibleImages((prevVisibleImages) => prevVisibleImages + 8);
  };

  return (
    <section className="smile-section">
      <div className="container">
        <div className="row row-cols-md-4 row-cols-sm-2 row-cols-1">
          {images.slice(0, visibleImages).map((image, index) => (
            <div className="col mb-md-5 mb-5" key={index}>
              <div className="smile-img animate__animated animate__zoomIn" onClick={() => openLightbox(index)}>
                <img className='' src={image} alt={`Best Resorts with Private Pool in Alibaug ${index + 1}`} />
                <div className="smile-icon">
                  <TiEyeOutline />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row text-center mb-4" bis_skin_checked={1}>
  <div className="package-bottom-btn" bis_skin_checked={1}>
  {visibleImages < images.length && (
          <button className="button-fill-primary" onClick={loadMoreImages}>Load More</button>
        )}
    {/* <a href="#" className="button-fill-primary" bis_skin_checked={1}>
      View More
    </a> */}
  </div>
</div>
        {lightboxOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={closeLightbox}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          />
        )}
      </div>
    </section>
  );
};

export default GalleyCard;
