import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropertyData from "../Components/PropertyData";
import RoomCard from "../Pages/RoomCard";
import Breadcrumbs from "./breadcrumbs";
import CardsRoom from "./CardsRoom";
const PropertyType = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  const search = useLocation();
  const path = search.pathname;
  const propertyDetails = PropertyData.find((e) => e.slugs == path);
  // console.log(propertyDetails);
  return (
    <>
      <Breadcrumbs  bannerName={propertyDetails.propertyName} />
      <section>
        <div className="container mt-5">
         <div className="row">
         {propertyDetails.hotelList.map((v, i) => {
            return (
              <>
              <div className="col-md-4">
              <CardsRoom detail={v} name={propertyDetails.propertyName} slug={propertyDetails.slugs} />
                {/* <RoomCard detail={v} slug={propertyDetails.slugs} /> */}
              </div>
              </>
            );
          })}
          {/* {propertyDetails.hotelList.length == 0?(<><h1 className="text-center my-5 mx-5">Coming Soon</h1></>):(<p></p>)} */}
         </div>
          {/* <RoomCard propertyDetails={propertyDetails} /> */}
        </div>
      </section>
    </>
  );
};

export default PropertyType;
