import React from 'react'

const breadcrumbs = ({bannerName,property}) => {
  return (
    <div className="breadcrumb breadcrumb-style-one" bis_skin_checked={1}>
        <div className="container" bis_skin_checked={1}>
          <div className="col-lg-12 text-center" bis_skin_checked={1}>
            <h2 className="breadcrumb-title">{bannerName}</h2>
            <ul className="d-flex justify-content-center breadcrumb-items">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              {property?(<li className="breadcrumb-item active">
                {property}
              </li>):(<p></p>)}
              <li className="breadcrumb-item active">
                {bannerName}
              </li>
            </ul>
          </div>
        </div>
      </div>
  )
}

export default breadcrumbs