// aroma villa
import aroma1 from "../Asserts/aroma-villa/travelBees (1).webp";
import aroma2 from "../Asserts/aroma-villa/travelBees (2).webp";
import aroma3 from "../Asserts/aroma-villa/travelBees (3).webp";
import aroma8 from "../Asserts/aroma-villa/travelBees (8).webp";
import aroma4 from "../Asserts/aroma-villa/travelBees (4).webp";
import aroma5 from "../Asserts/aroma-villa/travelBees (5).webp";
import aroma6 from "../Asserts/aroma-villa/travelBees (6).webp";
import aroma7 from "../Asserts/aroma-villa/travelBees (7).webp";
import aroma9 from "../Asserts/aroma-villa/travelBees (9).webp";
import aroma10 from "../Asserts/aroma-villa/travelBees (10).webp";
import aroma11 from "../Asserts/aroma-villa/travelBees (11).webp";
import aroma12 from "../Asserts/aroma-villa/travelBees (12).webp";
import aroma13 from "../Asserts/aroma-villa/travelBees (13).webp";

// sam villa
import sam1 from "../Asserts/sam villa/sam-villa (1).webp";
import sam2 from "../Asserts/sam villa/sam-villa (2).webp";
import sam3 from "../Asserts/sam villa/sam-villa (3).webp";
import sam4 from "../Asserts/sam villa/sam-villa (4).webp";
import sam5 from "../Asserts/sam villa/sam-villa (5).webp";
import sam6 from "../Asserts/sam villa/sam-villa (6).webp";
import sam7 from "../Asserts/sam villa/sam-villa (7).webp";
import sam8 from "../Asserts/sam villa/sam-villa (8).webp";
import sam9 from "../Asserts/sam villa/sam-villa (9).webp";
import sam10 from "../Asserts/sam villa/sam-villa (10).webp";
import sam11 from "../Asserts/sam villa/sam-villa (11).webp";
import sam12 from "../Asserts/sam villa/sam-villa (12).webp";
import sam13 from "../Asserts/sam villa/sam-villa (13).webp";
import sam14 from "../Asserts/sam villa/sam-villa (14).webp";
import sam15 from "../Asserts/sam villa/sam-villa (15).webp";
import sam16 from "../Asserts/sam villa/sam-villa (16).webp";
import sam17 from "../Asserts/sam villa/sam-villa (17).webp";

//start villa
// aroma
import TRVLBV01_1 from "../Asserts/villa/TRVLBV01/TRVLBV01 (1).webp";
import TRVLBV01_2 from "../Asserts/villa/TRVLBV01/TRVLBV01 (2).webp";
import TRVLBV01_3 from "../Asserts/villa/TRVLBV01/TRVLBV01 (3).webp";
import TRVLBV01_4 from "../Asserts/villa/TRVLBV01/TRVLBV01 (4).webp";
import TRVLBV01_5 from "../Asserts/villa/TRVLBV01/TRVLBV01 (5).webp";
import TRVLBV01_6 from "../Asserts/villa/TRVLBV01/TRVLBV01 (6).webp";
import TRVLBV01_7 from "../Asserts/villa/TRVLBV01/TRVLBV01 (7).webp";
import TRVLBV01_8 from "../Asserts/villa/TRVLBV01/TRVLBV01 (8).webp";
import TRVLBV01_9 from "../Asserts/villa/TRVLBV01/TRVLBV01 (9).webp";
import TRVLBV01_10 from "../Asserts/villa/TRVLBV01/TRVLBV01 (10).webp";
import TRVLBV01_11 from "../Asserts/villa/TRVLBV01/TRVLBV01 (11).webp";
import TRVLBV01_12 from "../Asserts/villa/TRVLBV01/TRVLBV01 (12).webp";
import TRVLBV01_13 from "../Asserts/villa/TRVLBV01/TRVLBV01 (13).webp";
import TRVLBV01_14 from "../Asserts/villa/TRVLBV01/TRVLBV01 (14).webp";
import TRVLBV01_15 from "../Asserts/villa/TRVLBV01/TRVLBV01 (15).webp";
import TRVLBV01_16 from "../Asserts/villa/TRVLBV01/TRVLBV01 (16).webp";

//sam villa
import TRVLBV02_1 from "../Asserts/villa/TRVLBV02/TRVLBV02(1).webp";
import TRVLBV02_2 from "../Asserts/villa/TRVLBV02/TRVLBV02(2).webp";
import TRVLBV02_3 from "../Asserts/villa/TRVLBV02/TRVLBV02(3).webp";
import TRVLBV02_4 from "../Asserts/villa/TRVLBV02/TRVLBV02(4).webp";
import TRVLBV02_5 from "../Asserts/villa/TRVLBV02/TRVLBV02(5).webp";
import TRVLBV02_6 from "../Asserts/villa/TRVLBV02/TRVLBV02(6).webp";
import TRVLBV02_7 from "../Asserts/villa/TRVLBV02/TRVLBV02(7).webp";
import TRVLBV02_8 from "../Asserts/villa/TRVLBV02/TRVLBV02(8).webp";
import TRVLBV02_9 from "../Asserts/villa/TRVLBV02/TRVLBV02(9).webp";
import TRVLBV02_10 from "../Asserts/villa/TRVLBV02/TRVLBV02(10).webp";
import TRVLBV02_11 from "../Asserts/villa/TRVLBV02/TRVLBV02(11).webp";
import TRVLBV02_12 from "../Asserts/villa/TRVLBV02/TRVLBV02(12).webp";
import TRVLBV02_13 from "../Asserts/villa/TRVLBV02/TRVLBV02(13).webp";
//M M Villa
import TRVLBV03_1 from "../Asserts/villa/TRVLBV03/TRVLBV03(1).webp";
import TRVLBV03_2 from "../Asserts/villa/TRVLBV03/TRVLBV03(2).webp";
import TRVLBV03_3 from "../Asserts/villa/TRVLBV03/TRVLBV03(3).webp";
import TRVLBV03_4 from "../Asserts/villa/TRVLBV03/TRVLBV03(4).webp";
import TRVLBV03_5 from "../Asserts/villa/TRVLBV03/TRVLBV03(5).webp";
import TRVLBV03_6 from "../Asserts/villa/TRVLBV03/TRVLBV03(6).webp";
import TRVLBV03_7 from "../Asserts/villa/TRVLBV03/TRVLBV03(7).webp";
import TRVLBV03_8 from "../Asserts/villa/TRVLBV03/TRVLBV03(8).webp";
import TRVLBV03_9 from "../Asserts/villa/TRVLBV03/TRVLBV03(9).webp";
import TRVLBV03_10 from "../Asserts/villa/TRVLBV03/TRVLBV03(10).webp";
import TRVLBV03_11 from "../Asserts/villa/TRVLBV03/TRVLBV03(11).webp";
import TRVLBV03_12 from "../Asserts/villa/TRVLBV03/TRVLBV03(12).webp";
import TRVLBV03_13 from "../Asserts/villa/TRVLBV03/TRVLBV03(13).webp";
import TRVLBV03_14 from "../Asserts/villa/TRVLBV03/TRVLBV03(14).webp";
import TRVLBV03_15 from "../Asserts/villa/TRVLBV03/TRVLBV03(15).webp";
import TRVLBV03_16 from "../Asserts/villa/TRVLBV03/TRVLBV03(16).webp";
import TRVLBV03_17 from "../Asserts/villa/TRVLBV03/TRVLBV03(17).webp";
import TRVLBV03_18 from "../Asserts/villa/TRVLBV03/TRVLBV03(18).webp";
import TRVLBV03_19 from "../Asserts/villa/TRVLBV03/TRVLBV03(19).webp";
import TRVLBV03_20 from "../Asserts/villa/TRVLBV03/TRVLBV03(20).webp";
import TRVLBV03_21 from "../Asserts/villa/TRVLBV03/TRVLBV03(21).webp";
import TRVLBV03_22 from "../Asserts/villa/TRVLBV03/TRVLBV03(22).webp";
import TRVLBV03_23 from "../Asserts/villa/TRVLBV03/TRVLBV03(23).webp";
import TRVLBV03_24 from "../Asserts/villa/TRVLBV03/TRVLBV03(24).webp";
//End villa
// start hotel
// shukh sagar
import TRVLBH01_1 from "../Asserts/hotel/TRVLBH01/TRVLBH01(1).webp";
import TRVLBH01_2 from "../Asserts/hotel/TRVLBH01/TRVLBH01(2).webp";
import TRVLBH01_3 from "../Asserts/hotel/TRVLBH01/TRVLBH01(3).webp";
import TRVLBH01_4 from "../Asserts/hotel/TRVLBH01/TRVLBH01(4).webp";
import TRVLBH01_5 from "../Asserts/hotel/TRVLBH01/TRVLBH01(5).webp";
import TRVLBH01_6 from "../Asserts/hotel/TRVLBH01/TRVLBH01(6).webp";
import TRVLBH01_7 from "../Asserts/hotel/TRVLBH01/TRVLBH01(7).webp";
import TRVLBH01_8 from "../Asserts/hotel/TRVLBH01/TRVLBH01(8).webp";
import TRVLBH01_9 from "../Asserts/hotel/TRVLBH01/TRVLBH01(9).webp";
import TRVLBH01_10 from "../Asserts/hotel/TRVLBH01/TRVLBH01(10).webp";
import TRVLBH01_11 from "../Asserts/hotel/TRVLBH01/TRVLBH01(11).webp";
import TRVLBH01_12 from "../Asserts/hotel/TRVLBH01/TRVLBH01(12).webp";
import TRVLBH01_13 from "../Asserts/hotel/TRVLBH01/TRVLBH01(13).webp";
import TRVLBH01_14 from "../Asserts/hotel/TRVLBH01/TRVLBH01(14).webp";
import TRVLBH01_15 from "../Asserts/hotel/TRVLBH01/TRVLBH01(15).webp";
import TRVLBH01_16 from "../Asserts/hotel/TRVLBH01/TRVLBH01(16).webp";
import TRVLBH01_17 from "../Asserts/hotel/TRVLBH01/TRVLBH01(17).webp";
// end hotel
// resort
import TRVLBR01_1 from "../Asserts/resort/TRVLBR01/TRVLBR01-(1).jpeg";
import TRVLBR01_2 from "../Asserts/resort/TRVLBR01/TRVLBR01-(2).jpeg";
import TRVLBR01_3 from "../Asserts/resort/TRVLBR01/TRVLBR01-(3).jpeg";
import TRVLBR01_4 from "../Asserts/resort/TRVLBR01/TRVLBR01-(4).jpeg";
import TRVLBR01_5 from "../Asserts/resort/TRVLBR01/TRVLBR01-(5).jpeg";
import TRVLBR01_6 from "../Asserts/resort/TRVLBR01/TRVLBR01-(6).jpeg";
import TRVLBR01_7 from "../Asserts/resort/TRVLBR01/TRVLBR01-(7).jpeg";
import TRVLBR01_8 from "../Asserts/resort/TRVLBR01/TRVLBR01-(8).jpeg";
import TRVLBR01_9 from "../Asserts/resort/TRVLBR01/TRVLBR01-(9).jpeg";
import TRVLBR01_10 from "../Asserts/resort/TRVLBR01/TRVLBR01-(10).jpeg";
import TRVLBR01_11 from "../Asserts/resort/TRVLBR01/TRVLBR01-(11).jpeg";
import TRVLBR01_12 from "../Asserts/resort/TRVLBR01/TRVLBR01-(12).jpeg";
import TRVLBR01_13 from "../Asserts/resort/TRVLBR01/TRVLBR01-(13).jpeg";
import TRVLBR01_14 from "../Asserts/resort/TRVLBR01/TRVLBR01-(14).jpeg";
import TRVLBR01_15 from "../Asserts/resort/TRVLBR01/TRVLBR01-(15).jpeg";
import TRVLBR01_16 from "../Asserts/resort/TRVLBR01/TRVLBR01-(16).jpeg";
import TRVLBR01_17 from "../Asserts/resort/TRVLBR01/TRVLBR01-(17).jpeg";
import TRVLBR01_18 from "../Asserts/resort/TRVLBR01/TRVLBR01-(18).jpeg";
import TRVLBR01_19 from "../Asserts/resort/TRVLBR01/TRVLBR01-(19).jpeg";
import TRVLBR01_20 from "../Asserts/resort/TRVLBR01/TRVLBR01-(20).jpeg";
import TRVLBR01_21 from "../Asserts/resort/TRVLBR01/TRVLBR01-(21).webp";
import TRVLBR01_22 from "../Asserts/resort/TRVLBR01/TRVLBR01-(22).webp";
import TRVLBR01_23 from "../Asserts/resort/TRVLBR01/TRVLBR01-(23).webp";
import TRVLBR01_24 from "../Asserts/resort/TRVLBR01/TRVLBR01-(24).webp";

// banquet 1
import TRVLBBQ01_1 from "../Asserts/banquet/TRVLBBQ01/TRVLBBQ01(1).avif";
import TRVLBBQ01_2 from "../Asserts/banquet/TRVLBBQ01/TRVLBBQ01(2).avif"
import TRVLBBQ01_3 from "../Asserts/banquet/TRVLBBQ01/TRVLBBQ01(3).avif"
import TRVLBBQ01_4 from "../Asserts/banquet/TRVLBBQ01/TRVLBBQ01(4).avif"
// banquet 2
import TRVLBBQ02_1 from "../Asserts/banquet/TRVLBBQ02/TRVLBBQ02(1).avif";
// banquet 3
import TRVLBBQ03_1 from "../Asserts/banquet/TRVLBBQ03/TRVLBBQ03(1).avif";

const PropertyData = [
  {
    propertyName: "Villa",
    slugs: "/villas",
    hotelList: [
      {
        propertyName: "TRVLBV01",
        propertyCode: "Aroma Villa",
        slugs: "/TRVLBV01",
        propertyImage: [
          TRVLBV01_1,
          TRVLBV01_2,
          TRVLBV01_3,
          TRVLBV01_4,
          TRVLBV01_5,
          TRVLBV01_6,
          TRVLBV01_7,
          TRVLBV01_8,
          TRVLBV01_9,
          TRVLBV01_10,
          TRVLBV01_11,
          TRVLBV01_12,
          TRVLBV01_13,
          TRVLBV01_14,
          TRVLBV01_15,
          TRVLBV01_16,
        ],
        // propertyImage1: aroma8,
        // propertyImage2: aroma1,
        // propertyImage3: aroma8,
        // propertyImage4: aroma2,
      },
      {
        propertyName: "TRVLBV02",
        propertyCode: "Sam Villa",
        slugs: "/TRVLBV02",
        propertyImage: [
          TRVLBV02_1,
          TRVLBV02_2,
          TRVLBV02_3,
          TRVLBV02_4,
          TRVLBV02_5,
          TRVLBV02_6,
          TRVLBV02_7,
          TRVLBV02_8,
          TRVLBV02_9,
          TRVLBV02_10,
          TRVLBV02_11,
          TRVLBV02_12,
          TRVLBV02_13,
        ],
        // propertyImage1: sam1,
        // propertyImage1: sam2,
        // [
        //     sam1,
        //     sam2,
        // ]
      },
      {
        propertyName: "TRVLBV03",
        propertyCode: "Mm Villa",
        slugs: "/TRVLBV03",
        propertyImage: [
          TRVLBV03_1,
          TRVLBV03_2,
          TRVLBV03_3,
          TRVLBV03_4,
          TRVLBV03_5,
          TRVLBV03_6,
          TRVLBV03_7,
          TRVLBV03_8,
          TRVLBV03_9,
          TRVLBV03_10,
          TRVLBV03_11,
          TRVLBV03_12,
          TRVLBV03_13,
          TRVLBV03_14,
          TRVLBV03_15,
          TRVLBV03_16,
          TRVLBV03_17,
          TRVLBV03_18,
          TRVLBV03_19,
          TRVLBV03_20,
          TRVLBV03_21,
          TRVLBV03_22,
          TRVLBV03_23,
          TRVLBV03_24,
        ],
        // propertyImage1: sam1,
        // propertyImage1: sam2,
        // [
        //     sam1,
        //     sam2,
        // ]
      },
    ],
  },
  {
    propertyName: "Hotel",
    slugs: "/hotel",
    hotelList: [
      {
        propertyName: "TRVLBH01",
        slugs: "/TRVLBH01",
        propertyImage: [
          TRVLBH01_1,
          TRVLBH01_2,
          TRVLBH01_3,
          TRVLBH01_4,
          TRVLBH01_5,
          TRVLBH01_6,
          TRVLBH01_7,
          TRVLBH01_8,
          TRVLBH01_9,
          TRVLBH01_10,
          TRVLBH01_11,
          TRVLBH01_12,
          TRVLBH01_13,
          TRVLBH01_14,
          TRVLBH01_15,
          TRVLBH01_16,
          TRVLBH01_17,
        ],
        // propertyImage1: aroma8,
        // propertyImage2: aroma1,
        // propertyImage3: aroma8,
        // propertyImage4: aroma2,
      },
    ],
  },
  {
    propertyName: "Resort",
    slugs: "/resort",
    hotelList: [
      {
        propertyName: "TRVLBR01",
        slugs: "/TRVLBR01",
        propertyImage: [
          TRVLBR01_1,
      TRVLBR01_2,
      TRVLBR01_3,
      TRVLBR01_4,
      TRVLBR01_5,
      TRVLBR01_6,
      TRVLBR01_7,
      TRVLBR01_8,
      TRVLBR01_9,
      TRVLBR01_10,
      TRVLBR01_11,
      TRVLBR01_12,
      TRVLBR01_13,
      TRVLBR01_14,
      TRVLBR01_15,
      TRVLBR01_16,
      TRVLBR01_17,
      TRVLBR01_18,
      TRVLBR01_19,
      TRVLBR01_20,
      TRVLBR01_21,
      TRVLBR01_22,
      TRVLBR01_23,
      TRVLBR01_24
        ],
        // propertyImage1: aroma8,
        // propertyImage2: aroma1,
        // propertyImage3: aroma8,
        // propertyImage4: aroma2,
      },
    ],
  
  },
  {
    propertyName: "Banquet",
    slugs: "/banquet",
    hotelList: [
      {
        propertyName: "TRVLBBQ01",
        slugs: "/TRVLBBQ01",
        propertyImage:[
          TRVLBBQ01_1,
          TRVLBBQ01_2,
          TRVLBBQ01_3,
          TRVLBBQ01_4
        ]
      },
      {
        propertyName: "TRVLBBQ02",
        slugs: "/TRVLBBQ02",
        propertyImage:[
          TRVLBBQ02_1,
        ]
      },
      {
        propertyName: "TRVLBBQ03",
        slugs: "/TRVLBBQ03",
        propertyImage:[
          TRVLBBQ03_1,
        ]
      }
     
    ],
  },
];

export default PropertyData;
