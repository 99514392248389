import React from "react";
import "../Css/RoomDetailsPage.css";
import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import Breadcrumbs from "./breadcrumbs";
import { Image, Space } from "antd";
import { FaWifi } from "react-icons/fa6";
import { FaStar } from "react-icons/fa6";
import { TbAirConditioning } from "react-icons/tb";
import { FaPersonSwimming } from "react-icons/fa6";
import { RiFridgeLine } from "react-icons/ri";
import { BsFillSafe2Fill } from "react-icons/bs";
import { PiTelevisionSimpleBold } from "react-icons/pi";
// import Footer from "../../Component/Footer/FooterSec";
// import demo from "../../Images/Banner/DJI_0592.JPG";
import { Link, useLocation } from "react-router-dom";
import propertyData from "../Components/PropertyData";
import { Formik, Form, Field, ErrorMessage } from "formik";
import $ from 'jquery';
import { Progress } from "antd";
import VillaSlider from "./VillaSlider";
const RoomDetailsPage = () => {
  const serach = useLocation();
  // originalString.split(delimiter)
  const path = serach.pathname;
  // console.log(path, "path");
  const delimiter = "/";
  const resultArray = path.split(delimiter);
  const propertyType = "/" + resultArray[1];
  const propertyName = "/" + resultArray[2];
  // const resultArray = serach.split('/')
  const villaDetails = propertyData.find(
    (value) => value.slugs === propertyType
  );

  // const villaDetails = propertyData.find((v,i)=> {v.hotelList.map((e)=> e.slugs === path && return e)})
  // console.log(propertyData.map((v,i)=> {v.hotelList.find((e)=> e.slugs == path)}));
  // console.log(villaDetails.propertyName,"villaDetails");
  return (
    villaDetails &&
    villaDetails.hotelList
      .filter((value) => value.slugs === propertyName)
      .map((valueData) => (
        <>
        
          <Breadcrumbs property={resultArray[1]} bannerName={valueData.propertyName}/>
          {/* <BannerBar /> */}
          <div className="container">
            <div className="top-bottom">
              <div className="row">
                <div className="col text-right">
                  <div className="Room-Details-Head-Main-con">
                    <h2>{valueData.propertyName}</h2>
                    <p style={{ color: "orange" }}>
                      <FaStar /> <FaStar /> <FaStar /> <FaStar />
                      <FaStar />{" "}
                    </p>
                  </div>
                </div>
               
              </div>
            </div>
            <section>
            <div className="imagesectionn">
              {/* <VillaSlider images={valueData.propertyImage} /> */}
              <div className="container">
              <div className="row">
           {valueData.propertyImage.map((v,i)=>{
            return(<>
              <div className="col-md-4 col-lg-4">
              <div className="gallary-item" bis_skin_checked={1}>
                <Image
                  className="galley-img"
                  width="100%"
                  height="100%"
                  src={v}
                  preview={{
                    toolbarRender: (
                      _,
                      {
                        transform: { scale },
                        actions: {
                          onFlipY,
                          onFlipX,
                          onRotateLeft,
                          onRotateRight,
                          onZoomOut,
                          onZoomIn,
                        },
                      }
                    ) => (
                      <Space size={12} className="toolbar-wrapper">
                        <SwapOutlined rotate={90} onClick={onFlipY} />
                        <SwapOutlined onClick={onFlipX} />
                        <RotateLeftOutlined onClick={onRotateLeft} />
                        <RotateRightOutlined onClick={onRotateRight} />
                        <ZoomOutOutlined
                          disabled={scale === 1}
                          onClick={onZoomOut}
                        />
                        <ZoomInOutlined
                          disabled={scale === 50}
                          onClick={onZoomIn}
                        />
                      </Space>
                    ),
                  }}
                />
              </div>
            </div>
            </>)
           })}
          
           
          </div>
              </div>
              
            </div>
            </section>
            <section className="enquiry-form mb-3">
                <div className="container shadow">
                <div className="form-col col-lg-12 col-md-12 col-sm-12 py-md-5 px-md-5 py-sm-2 px-sm-2">
        <div className="inner">
          <div
            className="form-box default-form contact-form-two"
        
          >
            <h3>Enquiry Now</h3>
            <div className="text mb-2">
              Please complete the details below and then click on Submit and
              we’ll be in contact
            </div>
            <div className="form-group">
            <Formik
                      initialValues={{ email: "", Name: "", Cnumber: "",  message: "", selectedProperty:"" }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.Name) {
                          errors.Name = "Required";
                        } else if (values.Name.length >= 10) {
                          errors.Name = "Enter A Name";
                        }

                        if (!values.Cnumber) {
                          errors.Cnumber = "Required";
                        } else if (values.Cnumber.length >= 11) {
                          errors.Name = "Invalid Contact Number";
                        }

                        if (!values.email) {
                          errors.email = "Required";
                        } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                            values.email
                          )
                        ) {
                          errors.email = "Invalid email address";
                        }
                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        // setTimeout(() => {

                        var body =
                        '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#eb6e14">Travel Beez Enquiry</h2><p>Hello</p><p>Thank you for your interest in our services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#0a500a"><strong>Name:</strong></td><td style="text-align:left">' +  values.Name + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Email Id:</strong></td><td style="text-align:left">' +  values.email + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Contact No:</strong></td><td style="text-align:left">' +  values.Cnumber + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Property:</strong></td><td style="text-align:left">' +  valueData.propertyName + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Message:</strong></td><td style="text-align:left">' +  values.message + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Travel Beez</p></div></body></html>';

                        $.post(
                          "https://skdm.in/server/v1/send_lead_mail.php",
                          {
                            toEmail: "travelbee2729@gmail.com",
                            fromEmail: "skdmlead@gmail.com",
                            mailSubject: "New Lead genration",
                            mailBody: body,
                            accountName: "travel beez",
                            accountLeadSource:
                              "",
                          },

                          function (dataa, status) {
                            // console.log('data :' + dataa);
                            // console.log("name:" + custName);
                          }
                        );

                        alert(
                          "Your Form has Submitted Our team will contact with You  soon."
                        );

                        // e.preventDefault()

                        setSubmitting(false);
                          // Reset the form after submission
                    resetForm();

                        // }, 10);
                      }}
                    >
                      <Form id="contact">
                    <Field
                      name="Name"
                      type="text"
                      
                      placeholder="Your name"
                    />
                     <ErrorMessage name="Name" component="div" />
                     <Field
                     name="Cnumber"
                     placeholder="Your Phone Number*"
                     type="tel"
                     tabIndex={3}
                     required=""
                    />
                      <ErrorMessage name="Cnumber" component="div" /> 
                    <Field
                    name="email"
                      type="text"
                     
                      placeholder="Email"
                    />
                    <ErrorMessage name="email" component="div" />
                    <Field
                     name="selectedProperty"
                    //  placeholder=""
                    value={valueData.propertyName}
                     type="text"
                     readonly
                    //  tabIndex={3}
                     required=""
                    />
                      {/* <ErrorMessage name="Cnumber" component="div" />  */}
                     
                    <Field  as="textarea"
                     name="message"
                     
                      placeholder="Message"
                      defaultValue={""}
                    />
                      <ErrorMessage name="message" component="div" />
                      <div className='btn-style-one'>
                    <button className="contact_form_submit btn-title" type="submit" >Send</button>
                    </div>
                    </Form>
              </Formik>
              </div>
            {/* <form
              method="post"
              action="sendemail.php"
              id="contact-form"
              noValidate="novalidate"
            >
              <div className="form-group">
                <div className="field-inner">
                  <input
                    type="text"
                    name="username"
                    defaultValue=""
                    placeholder="First name"
                    required=""
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="field-inner">
                  <input
                    type="text"
                    name="lastname"
                    defaultValue=""
                    placeholder="Last name"
                    required=""
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="field-inner">
                  <input
                    type="email"
                    name="email"
                    defaultValue=""
                    placeholder="Email"
                    required=""
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="field-inner">
                  <input
                    type="text"
                    name="phone"
                    defaultValue=""
                    placeholder="Phone"
                    required=""
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="field-inner">
                  <textarea
                    name="message"
                    placeholder="Message"
                    required=""
                    defaultValue={""}
                  />
                </div>
              </div>
              <div className="form-group">
                <button className="btn-style-one">
                  <span className="btn-title">Send Message</span>
                </button>
              </div>
            </form> */}
          </div>
        </div>
      </div>
                </div>
            </section>
            {/* <div className="top-bottom">
              <div className="row">
                <div className="col-6">
                  <div className="Room-Details-Page-Description">
                    <h3>Description</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Eius officia aspernatur maxime harum vitae doloremque
                      consequatur porro, eum molestias non vel modi suscipit
                      similique, labore reiciendis. Quidem est assumenda dolore.
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Cumque nulla repellat, dolor rem officia recusandae ipsam
                      odio? Officiis molestias necessitatibus, nemo ipsa
                      laboriosam aspernatur libero aliquam asperiores quae modi
                      quo. Lorem, ipsum dolor sit amet consectetur adipisicing
                      elit. Sapiente, cupiditate iste autem quas iure similique
                      libero! Numquam, earum molestiae facere nam delectus sed
                      eos corrupti. Quos odit aliquid amet laudantium. Lorem
                      ipsum dolor, sit amet consectetur adipisicing elit. Ea
                      debitis omnis quaerat atque cumque? Rem rerum sequi
                      doloremque quas, blanditiis delectus dolorum dolor
                      voluptate dignissimos excepturi, et officia ut cupiditate.
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Rem, hic voluptatem? Ullam ut, ex recusandae pariatur, et
                      eos perspiciatis quo exercitationem dicta necessitatibus
                      praesentium molestiae voluptas, accusantium magni
                      quibusdam nulla!
                    </p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="Room-Details-Page-Description">
                    <h3>Room Aminity</h3>
                    <div className="row">
                      <div className="col-6">
                        <div className="ul styleul">
                          <li className="iconsection">
                            <span className="serviceicon">
                              <TbAirConditioning />
                            </span>
                            A/C
                          </li>
                          <li className="iconsection">
                            <span className="serviceicon">
                              <FaPersonSwimming />
                            </span>
                            Swiming Pool
                          </li>
                          <li className="iconsection">
                            <span className="serviceicon">
                              <RiFridgeLine />
                            </span>
                            Fridge
                          </li>
                          <li className="iconsection">
                            <span className="serviceicon">
                              <BsFillSafe2Fill />
                            </span>
                            Safe Box
                          </li>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="ul styleul">
                          <li className="iconsection">
                            <span className="serviceicon">
                              <FaWifi />
                            </span>
                            Wifi
                          </li>
                          <li className="iconsection">
                            <span className="serviceicon">
                              <PiTelevisionSimpleBold />
                            </span>
                            T.v
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="top-bottom">
              <div className="row">
                <div className="col-6">
                  <div className="Room-Details-Page-Description">
                    <h3>Review</h3>
                    <div className="rating-first">
                      <p className="rating-text" style={{ color: "white" }}>
                        4.8
                      </p>
                      <h4 className="rating-text">very good</h4>
                      <div
                        className="Room-Details-Star-con"
                        style={{ color: "orange" }}
                      >
                        <FaStar /> <FaStar /> <FaStar /> <FaStar />
                      </div>
                    </div>
                    <div className="rating-Progress-bar-con">
                      <div className="Rating-Progress-Bar">
                        <h4>Accommodation</h4>
                        <Progress percent={60} />
                      </div>
                      <div className="Rating-Progress-Bar">
                        <h4>Location</h4>
                        <Progress percent={70} />
                      </div>
                      <div className="Rating-Progress-Bar">
                        <h4>Meals</h4>
                        <Progress percent={80} />
                      </div>
                      <div className="Rating-Progress-Bar">
                        <h4>Facilities</h4>
                        <Progress percent={90} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="Room-Details-Facility-head">
                    <h3>Our Facility</h3>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <ul className="liststyle-review">
                        <li>Kettle, tea & coffee</li>
                        <li>Comfortable beds</li>
                        <li>Free Wi-fi</li>
                        <li>Air conditione</li>
                        <li>Breakfast included</li>
                        <li>Wardrobe</li>
                      </ul>
                    </div>
                    <div className="col-6">
                      <ul className="liststyle-review">
                        <li>Bathrobe & slippers</li>
                        <li>Balcony or terrace</li>
                        <li>Safe</li>
                        <li>Shower bathtub</li>
                        <li>Working table</li>
                        <li>Shampoo and soap</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="Room-Details-Page-Description">
                    <h3>Review</h3>
                    <p>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Vel totam similique ipsum, aspernatur rem ullam veritatis
                      ipsa accusantium voluptas minima facilis beatae ut esse
                      accusamus vitae? Officiis alias commodi ad? Lorem, ipsum
                      dolor sit amet consectetur adipisicing elit. Placeat
                      architecto nulla accusamus voluptates vel laudantium
                      beatae quibusdam optio, porro nisi vitae ad similique
                      veritatis odit libero assumenda reprehenderit sequi modi!
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Libero tenetur odit officiis error quasi vel corrupti
                      illum dicta consequuntur, quisquam fuga rem quaerat at
                      alias animi dignissimos praesentium aspernatur recusandae!
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
            <div>
              {/* <div className="row">
                        <div className="col-12">
                            <div className='Room-Details-Plan-Head-con'>
                                <h3>Room plans</h3>
                            </div>
                        </div>

                        <div className='Room-Details-Plan-Tabs'>
                            <div className="row">
                                <div className="col-2">
                                    <div className='filterroom'>
                                        <input type="radio" id="html" name="fav_language" defaultValue="HTML" />
                                        <span className='paddinf10'> Room only</span>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className='filterroom'>
                                        <input type="radio" id="html" name="fav_language" defaultValue="HTML" />
                                        <span className='paddinf10'> With breakfast Included</span>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className='filterroom'>
                                        <input type="radio" id="html" name="fav_language" defaultValue="HTML" />
                                        <span className='paddinf10'>Breakfast & Linch Included</span>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className='filterroom'>
                                        <input type="radio" id="html" name="fav_language" defaultValue="HTML" />
                                        <span className='paddinf10'>Custom</span>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div> */}
              {/* <div className="row">
                        <div className='filterroomsection'>
                            <div className='Room-Details-Plan-Info-Head-con'>
                                <h4>
                                    Room plaing
                                </h4>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className='Room-Details-Plan-Info-con'>
                                        <ul>
                                            <li>
                                                No meals included
                                            </li>
                                            <li>
                                                20% discount on food and soft Beverages
                                            </li>
                                            <li>
                                                20% discount on food and soft Beverages
                                            </li>
                                            <p style={{ color: "#2d5155" }}>MORE DETAILS</p>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className='left-sideprce'>
                                        <p>pernight</p>
                                        <h3>$340</h3>
                                        <p>+120 tax & fees</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='filterroomsection'>
                            <div className='Room-Details-Plan-Info-Head-con'>
                                <h4>
                                    Room plaing
                                </h4>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className='Room-Details-Plan-Info-con'>
                                        <ul>
                                            <li>
                                                No meals included
                                            </li>
                                            <li>
                                                20% discount on food and soft Beverages
                                            </li>
                                            <li>
                                                20% discount on food and soft Beverages
                                            </li>
                                            <p style={{ color: "#2d5155" }}>MORE DETAILS</p>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className='left-sideprce'>
                                        <p>pernight</p>
                                        <h3>$340</h3>
                                        <p>+120 tax & fees</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='filterroomsection'>
                            <div className='Room-Details-Plan-Info-Head-con'>
                                <h4>
                                    Room plaing
                                </h4>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className='Room-Details-Plan-Info-con'>
                                        <ul>
                                            <li>
                                                No meals included
                                            </li>
                                            <li>
                                                20% discount on food and soft Beverages
                                            </li>
                                            <li>
                                                20% discount on food and soft Beverages
                                            </li>
                                            <p style={{ color: "#2d5155" }}>MORE DETAILS</p>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className='left-sideprce'>
                                        <p>pernight</p>
                                        <h3>$340</h3>
                                        <p>+120 tax & fees</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> */}
            </div>
          </div>
        </>
      ))
  );
};

export default RoomDetailsPage;
