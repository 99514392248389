// import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'animate.css';
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css"
import './App.css';
import Home from './Components/Home';
import CardDetails from "./Pages/CardDetails";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Contact from "./Pages/Contact";
import PropertyType from "./Pages/PropertyType";
import PropertyData from "./Components/PropertyData";
import RoomDetailsPage from "./Pages/RoomDetailsPage";
import About from "./Components/About";
import Event from "./Pages/Event";
import ErrorPage from "./Pages/ErrorPage";
import Gallerys from "./Pages/Gallerys";
// import Gallerys from "./Pages/Gallerys";
function App() {
  return (
    <>
    <BrowserRouter>
    <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/property-type" element={<PropertyType />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/About" element={<About />} />
        <Route path="/Events" element={<Event />} />
        <Route path="/Gallery" element={<Gallerys/>} />
        <Route path="/*" element={<ErrorPage />} />
        {PropertyData.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.slugs}
              element={<PropertyType/>}
            />
          ))}
          {PropertyData.map((property, index) => (
        <Route
          key={index}
          exact
          path={property.slugs}
          element={<PropertyType/>}
        />
      ))}

      {/* Generate routes for each hotel within the property */}
      {PropertyData.map((property, index) => (
        property.hotelList.map((hotel, hotelIndex) => (
          <Route
            key={hotelIndex}
            exact
            path={property.slugs+hotel.slugs}
            element={<CardDetails />}
          />
        ))
      ))}
          {/* {PropertyData.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.slugs}
              element={<RoomDetailsPage />}
            />
          ))} */}
      </Routes>
      <Footer />
    </BrowserRouter>
    </>
  );
}

export default App;
