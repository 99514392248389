import React, { useState } from 'react';
import { Nav, Navbar, Container } from "react-bootstrap";
import "../Css/Header.css"
// import Logo from "../assets/logo.png"
import { Link } from "react-router-dom";
// import Logo from "../assets/vidhi/logo.JPG";
import PropertyData from "../Components/PropertyData";
import NavDropdown from 'react-bootstrap/NavDropdown';
import propertyType from '../Components/PropertyData';
import logo from "../Asserts/2.png";

const Header = () => {
  return (
    <>
    <div className="nav-bar-component">
        <section className="NavSection">
            {/* <div className="container"> */}
            <Navbar collapseOnSelect expand="lg" variant="dark" >
                <Container>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Brand href="#home">
                        <Link to='/'>
                        <div className='NavLogo'>
                             <img src={logo}  alt="  logo" /> 
                        </div>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-start">
                        <Nav>
                            <Nav.Link href="#"> <Link to="/">Home</Link></Nav.Link>
                            <Nav.Link href="#"><Link to="/About">About us </Link></Nav.Link>
                            <Nav.Link href="#"><Link to="/Events">Events</Link></Nav.Link>
                            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              
              {propertyType.map((v,i)=>{
                                const { propertyName ,slugs} =v
                                return(
                                    <>
                                    <NavDropdown.Item href={slugs}>
                                        {propertyName}
                                    </NavDropdown.Item> 
                                    </>
                                )
                            })
            </NavDropdown> */}
                            {/* <NavDropdown title="Dropdowns" id="basic-nav-dropdown">
                            {propertyType.map((v,i)=>{
                                const { propertyName ,slugs} =v
                                return(
                                    <>
                                    <NavDropdown.Item href={slugs}>
                                        {propertyName}
                                    </NavDropdown.Item> 
                                    </>
                                )
                            })
            </NavDropdown> */}
                            {/* <!-- Create a list item with the class "nav-item dropdown" for the dropdown menu --> */}
      {/* <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropdown
        </a>

        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
      </li> */}

                            {/* <button className="dropdownShow nav-link" id=""> Courses                                      

                                      <div className="dorpdown">
                                      <div className="dropdown_list">
           
           
                                      {PropertyData.map((ele) => {
                            const { propertyName, slugs } = ele;
                            return (
                              <>
                                <Nav.Link href={slugs}>
                                  <Link  to={slugs}  className="dropdown-item" >{propertyName}</Link>
                                </Nav.Link>
                              </>
                            );
                          })}
                           
              
               
                <ul className="dropdown-menu">
                
                 
                  
                </ul>
                
              </div>
              </div>
              </button> */}
              <NavDropdown title="Property" id="basic-nav-dropdown">
              {/* <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item> */}
              {PropertyData.map((ele) => {
                                    const { propertyName ,slugs } = ele;
                                    return (
                                    <>
                                    <Nav.Link href="/"><Link to={`${slugs}`}>{propertyName}</Link></Nav.Link>
                                    </>
                                    );
                                })}
            </NavDropdown>
                            {/* <button className="dropdownShow">
                            Property Type
                            <div className="dorpdown">
                                <div className="dropdown_list">
                                {PropertyData.map((ele) => {
                                    const { propertyName ,slugs } = ele;
                                    return (
                                    <>
                                    <Nav.Link href="/"><Link to={`${slugs}`}>{propertyName}</Link></Nav.Link>
                                    </>
                                    );
                                })}
                                </div>
                            </div>
                            </button> */}
                            {/* <Nav.Link href="#"><Link to="/property-type">Property Type</Link></Nav.Link> */}
                            <Nav.Link href="#"><Link to="/Gallery">Gallery</Link></Nav.Link>
                            <Nav.Link href="#"><Link to="/Contact">Contact Us</Link></Nav.Link>
                            
                          
                        </Nav>
                    </Navbar.Collapse>
                    <Nav className="Social-media-icons">
                    </Nav>
                </Container>
            </Navbar>
            {/* </div> */}
        </section>

    </div>
</>
  )
}

export default Header
