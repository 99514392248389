import React from "react";
import "../Css/Home.css";
import wdevent5 from "../Asserts/weedingEvents/WeddingEvents(5).webp";
import villa4 from "../Asserts/villa/TRVLBV01/TRVLBV01 (4).webp";
import aboutUs1 from "../Asserts/websiteImages/1.webp";
import serviceCorporate from "../Asserts/websiteImages/corporateServices.jpg";
import serviceWedding from "../Asserts/websiteImages/Church-Wedding-Ceremony.webp";
import serviceGrounp from "../Asserts/websiteImages/goa-group-bookings.webp";
import gallery1 from "../Asserts/weedingEvents/WeddingEvents(1).webp";
import gallery2 from "../Asserts/weedingEvents/WeddingEvents(2).webp";
import gallery3 from "../Asserts/weedingEvents/WeddingEvents(3).webp";
import gallery4 from "../Asserts/weedingEvents/WeddingEvents(4).webp";
import gallery5 from "../Asserts/weedingEvents/WeddingEvents(5).webp";
import gallery6 from "../Asserts/villa/TRVLBV01/TRVLBV01 (1).webp";
import bannerBanquet from "../Asserts/websiteImages/banquet.webp";
import bannerHotel from "../Asserts/websiteImages/hotels.webp";


import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { Image, Space } from "antd";

import { Link } from "react-router-dom";

import TestimonialSlider from "../Pages/TestimonialSlider";
import VillaImg from "../Asserts/aroma-villa/travelBees (2).webp";
import resortImg from "../Asserts/aroma-villa/travelBees (11).webp";
import propertyResort from "../Asserts/villa/TRVLBV02/TRVLBV02(13).webp";

import { FaCalendarAlt } from "react-icons/fa";
import { FaRegSmile } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi";
import { MdOutlineHolidayVillage } from "react-icons/md";
import { TiWorld } from "react-icons/ti";
import { MdOutlineEventAvailable } from "react-icons/md";
import { FaAward } from "react-icons/fa";
import { FaMap } from "react-icons/fa";
import HomeSwiper from "../Pages/HomeSwiper";
import Counter from "../Pages/Counter";
import EventsOrganizer from "../Pages/EventsOrganizer";
const Home = () => {
  return (
    <>
      <div
        className="hero-area hero-style-one overflow-hidden"
        bis_skin_checked={1}
      >
        <div className="container-fluid p-0" bis_skin_checked={1}>
          <HomeSwiper />
        </div>
        <div
          className="hero-pagination d-block w-auto swiper-pagination-custom swiper-pagination-horizontal"
          bis_skin_checked={1}
        >
          01
        </div>
      </div>
      {/* About us Start */}
      <div className="about-main-wrappper pt-100" bis_skin_checked={1}>
        <div className="container" bis_skin_checked={1}>
          <div className="about-tab-wrapper" bis_skin_checked={1}>
            <div
              className="row justify-content-center align-items-center"
              bis_skin_checked={1}
            >
              <div className="col-lg-6" bis_skin_checked={1}>
                <div
                  className="about-tab-image-grid text-center"
                  bis_skin_checked={1}
                >
                  <div
                    className="about-video d-inline-block mb-5"
                    bis_skin_checked={1}
                  >
                    <img src={villa4} alt="" />
                    <div className="video-overlay" bis_skin_checked={1}></div>
                  </div>
                  <div className="row float-images g-4" bis_skin_checked={1}>
                    <div
                      className="col-lg-6 col-md-6 col-sm-6"
                      bis_skin_checked={1}
                    >
                      <div className="about-image" bis_skin_checked={1}>
                        <img src={wdevent5} alt="" />
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-6"
                      bis_skin_checked={1}
                    >
                      <div className="about-image" bis_skin_checked={1}>
                        <img src={aboutUs1} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-5 mt-lg-0" bis_skin_checked={1}>
                <div className="about-tab-wrap" bis_skin_checked={1}>
                  <h2 className="about-wrap-title">
                    About Our Company And <span>What We Are</span> Offer.
                  </h2>
                  <div className="about-tab-switcher" bis_skin_checked={1}>
                    <ul
                      className="nav nav-pills mb-3 justify-content-md-between justify-content-center"
                      id="about-tab-pills"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link active"
                          id="pills-about1"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills1"
                          role="tab"
                          aria-controls="about-pills1"
                          aria-selected="true"
                          bis_skin_checked={1}
                        >
                          <h3>5</h3>
                          <h6>Years Experience</h6>
                        </div>
                      </li>
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link"
                          id="pills-about2"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills2"
                          role="tab"
                          aria-controls="about-pills2"
                          aria-selected="false"
                          bis_skin_checked={1}
                        >
                          <h3>40+</h3>
                          <h6>Tour Guide</h6>
                        </div>
                      </li>
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link"
                          id="pills-about3"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills3"
                          role="tab"
                          aria-controls="about-pills3"
                          aria-selected="false"
                          bis_skin_checked={1}
                        >
                          <h3>50+</h3>
                          <h6>Travelar Connect</h6>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="tab-content about-tab-content"
                    id="pills-tabContent"
                    bis_skin_checked={1}
                  >
                    <div
                      className="tab-pane fade show active"
                      id="about-pills1"
                      role="tabpanel"
                      aria-labelledby="pills-about1"
                      bis_skin_checked={1}
                    >
                      <p>
                        Explore the ethos of our company and the extensive array
                        of offerings we proudly present. With a diverse
                        portfolio encompassing numerous resorts, villas, banquet
                        facilities, and hotels, we cater to a broad spectrum of
                        preferences. Our commitment to excellence extends to
                        crafting unforgettable experiences, ensuring every
                        resort stay, villa retreat, banquet event, and hotel
                        accommodation exceeds expectations. Immerse yourself in
                        the exceptional, as we continue to set new standards in
                        hospitality, offering unparalleled variety and quality
                        across our distinctive properties.
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="about-pills2"
                      role="tabpanel"
                      aria-labelledby="pills-about2"
                      bis_skin_checked={1}
                    >
                      <p>
                        {" "}
                        Explore the ethos of our company and the extensive array
                        of offerings we proudly present. With a diverse
                        portfolio encompassing numerous resorts, villas, banquet
                        facilities, and hotels, we cater to a broad spectrum of
                        preferences. Our commitment to excellence extends to
                        crafting unforgettable experiences, ensuring every
                        resort stay, villa retreat, banquet event, and hotel
                        accommodation exceeds expectations. Immerse yourself in
                        the exceptional, as we continue to set new standards in
                        hospitality, offering unparalleled variety and quality
                        across our distinctive properties.
                      </p>
                    </div>
                    {/* <div
                      className="tab-pane fade"
                      id="about-pills3"
                      role="tabpanel"
                      aria-labelledby="pills-about3"
                      bis_skin_checked={1}
                    >
                      <p>
                        Duis rutrum nisl urna. Maecenas vel libero faucibus nisi
                        venenatis hendrerit a id lectus. Suspendissendt blandit
                        interdum. Sed pellentesque at nunc eget consente
                        consectetur.Duis rutrum nisl urna. Maecenas vel libero
                        faucibus nisi venenatis hendrerit a id lectus.
                        Suspendissendt blandit interdum. Sed pellentesque at
                        nunc consectetur.Duis rutrum nisl urna. Maecenas vel
                        libero faucibus nisi venenatis hendrerit a id lectus.
                        Suspendissendt blandit interdum. Sed pellentesque at
                        nunc consectetur.Duis rutrum nisl urna. Maecenas vel
                        libero faucibus nisi venenatis hendrerit a id lectus.
                        Suspendissendt blandit interdum. Sed pellentesque at
                        nunc consectetur.
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row pt-100 align-items-center" bis_skin_checked={1}>
      <div className="col-lg-6" bis_skin_checked={1}>
        <div className="achievement-counter-wrap" bis_skin_checked={1}>
          <h2 className="about-wrap-title">
            Why Package Book With
            <span>TourX Pro?</span>
          </h2>
          <div className="achievement-counter-cards" bis_skin_checked={1}>
            <div
              className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center "
              bis_skin_checked={1}
            >
              <div className="counter-box mb-sm-0 mb-3" bis_skin_checked={1}>
                <h2>500+</h2>
                <h6>Awesome Tour</h6>
              </div>
              <p>
                Duis rutrum nisl urna. Maecenas vel libero faucibus nisi
                venenatis hendrerit a id lectus. Suspendissendt enlane molestie
                turpis nec lacinia vehicula.
              </p>
            </div>
            <div
              className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center"
              bis_skin_checked={1}
            >
              <div className="counter-box mb-3" bis_skin_checked={1}>
                <h2>300+</h2>
                <h6>Destinations</h6>
              </div>
              <p>
                Duis rutrum nisl urna. Maecenas vel libero faucibus nisi
                venenatis hendrerit a id lectus. Suspendissendt enlane molestie
                turpis nec lacinia vehicula.
              </p>
            </div>
            <div
              className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center"
              bis_skin_checked={1}
            >
              <div className="counter-box mb-3" bis_skin_checked={1}>
                <h2>150+</h2>
                <h6> Mountains</h6>
              </div>
              <p>
                Duis rutrum nisl urna. Maecenas vel libero faucibus nisi
                venenatis hendrerit a id lectus. Suspendissendt enlane molestie
                turpis nec lacinia vehicula.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6" bis_skin_checked={1}>
        <div className="about-image-group mt-5 mt-lg-0" bis_skin_checked={1}>
          <img
            src="assets/images/about/about-image-group2.png"
            alt=""
            className="img-fluid"
          />
        </div>
      </div>
    </div> */}
        </div>
      </div>
      {/* About us End */}
      {/* Services Start */}
     <EventsOrganizer />

      {/* services end */}
      <div
        className="destination-area destination-style-one pt-110 "
        bis_skin_checked={1}
      >
        <div className="container" bis_skin_checked={1}>
          <div className="row align-items-center" bis_skin_checked={1}>
            <div className="col-lg-8" bis_skin_checked={1}>
              <div className="section-head-alpha" bis_skin_checked={1}>
                <h2>Our Properties </h2>
                <p>
                Discover exquisite villas, luxurious resorts, versatile banquet spaces, and exceptional hotels in our premier properties.

                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container  px-sm-1 overflow-hidden" bis_skin_checked={1}>
          <div className="row">
            <div
              className="destination-card-style-one property-img col-md-6 mb-2"
              bis_skin_checked={1}
            >
              <div className="d-card-thumb" bis_skin_checked={1}>
                <a href="destination-details.html" bis_skin_checked={1}>
                  <img src={VillaImg} alt="" />
                </a>
              </div>
              <div className="d-card-overlay" bis_skin_checked={1}>
                <div className="d-card-content" bis_skin_checked={1}>
                  <h3 className="d-card-title">
                    <Link to="/Gallery">
                      <a href="#" bis_skin_checked={1}>
                        Villa
                      </a>
                    </Link>
                  </h3>
                  <div className="d-card-info" bis_skin_checked={1}>
                    <div className="place-count" bis_skin_checked={1}>
                      <span>14</span> Place
                    </div>
                    <div className="hotel-count" bis_skin_checked={1}>
                      <span>05</span> Hotel
                    </div>
                  </div>
                  <ul className="d-rating">
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="destination-card-style-one property-img col-md-6 mb-2"
              bis_skin_checked={1}
            >
              <div className="d-card-thumb" bis_skin_checked={1}>
                <a href="destination-details.html" bis_skin_checked={1}>
                  <img
                    src={bannerHotel}
                    alt=""
                  />
                </a>
              </div>
              <div className="d-card-overlay" bis_skin_checked={1}>
                <div className="d-card-content" bis_skin_checked={1}>
                  <h3 className="d-card-title">
                    <Link to="/Gallery">
                      <a href="#" bis_skin_checked={1}>
                        Hotel
                      </a>
                    </Link>
                  </h3>
                  <div className="d-card-info" bis_skin_checked={1}>
                    <div className="place-count" bis_skin_checked={1}>
                      <span>15</span> Place
                    </div>
                    <div className="hotel-count" bis_skin_checked={1}>
                      <span>05</span> Hotel
                    </div>
                  </div>
                  <ul className="d-rating">
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="destination-card-style-one property-img mb-2 col-md-6"
              bis_skin_checked={1}
            >
              <div className="d-card-thumb" bis_skin_checked={1}>
                <a href="destination-details.html" bis_skin_checked={1}>
                  <img src={propertyResort} alt="" />
                </a>
              </div>
              <div className="d-card-overlay" bis_skin_checked={1}>
                <div className="d-card-content" bis_skin_checked={1}>
                  <h3 className="d-card-title">
                    <Link to="/Gallery">
                      <a href="#" bis_skin_checked={1}>
                        Resort
                      </a>
                    </Link>
                  </h3>
                  <div className="d-card-info" bis_skin_checked={1}>
                    <div className="place-count" bis_skin_checked={1}>
                      <span>11</span> Place
                    </div>
                    <div className="hotel-count" bis_skin_checked={1}>
                      <span>05</span> Hotel
                    </div>
                  </div>
                  <ul className="d-rating">
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="destination-card-style-one property-img col-md-6"
              bis_skin_checked={1}
            >
              <div className="d-card-thumb" bis_skin_checked={1}>
                <a href="destination-details.html" bis_skin_checked={1}>
                  <img
                    src={bannerBanquet}
                    alt=""
                  />
                </a>
              </div>
              <div className="d-card-overlay" bis_skin_checked={1}>
                <div className="d-card-content" bis_skin_checked={1}>
                  <h3 className="d-card-title">
                    <Link to="/Gallery">
                      <a href="#" bis_skin_checked={1}>
                        Banquet
                      </a>
                    </Link>
                  </h3>
                  <div className="d-card-info" bis_skin_checked={1}>
                    <div className="place-count" bis_skin_checked={1}>
                      <span>20</span> Place
                    </div>
                    <div className="hotel-count" bis_skin_checked={1}>
                      <span>05</span> Hotel
                    </div>
                  </div>
                  <ul className="d-rating">
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Property Type end */}

      {/* Gallery start */}
      <div
        className="gallary-area gallary-style-one pt-110"
        bis_skin_checked={1}
      >
        <div className="container" bis_skin_checked={1}>
          <div className="row justify-content-center" bis_skin_checked={1}>
            <div className="col-lg-8" bis_skin_checked={1}>
              <div
                className="section-head-alpha section-padding-15 text-center mx-auto"
                bis_skin_checked={1}
              >
                <h2> Gallery</h2>
                <p>
                Explore our captivating gallery, showcasing a diverse array of images that capture the essence of our offerings. Immerse yourself in the visual richness that defines our unique spaces and experiences.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-lg-4">
              <div className="gallary-item" bis_skin_checked={1}>
                <Image
                  className="galley-img"
                  width="100%"
                  height="100%"
                  objectFit="cover"
                  src={gallery1}
                  preview={{
                    toolbarRender: (
                      _,
                      {
                        transform: { scale },
                        actions: {
                          onFlipY,
                          onFlipX,
                          onRotateLeft,
                          onRotateRight,
                          onZoomOut,
                          onZoomIn,
                        },
                      }
                    ) => (
                      <Space size={12} className="toolbar-wrapper">
                        <SwapOutlined rotate={90} onClick={onFlipY} />
                        <SwapOutlined onClick={onFlipX} />
                        <RotateLeftOutlined onClick={onRotateLeft} />
                        <RotateRightOutlined onClick={onRotateRight} />
                        <ZoomOutOutlined
                          disabled={scale === 1}
                          onClick={onZoomOut}
                        />
                        <ZoomInOutlined
                          disabled={scale === 50}
                          onClick={onZoomIn}
                        />
                      </Space>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="gallary-item" bis_skin_checked={1}>
                <Image
                  width="100%"
                  height="100%"
                  className="galley-img"
                  src={gallery2}
                  preview={{
                    toolbarRender: (
                      _,
                      {
                        transform: { scale },
                        actions: {
                          onFlipY,
                          onFlipX,
                          onRotateLeft,
                          onRotateRight,
                          onZoomOut,
                          onZoomIn,
                        },
                      }
                    ) => (
                      <Space size={12} className="toolbar-wrapper">
                        <SwapOutlined rotate={90} onClick={onFlipY} />
                        <SwapOutlined onClick={onFlipX} />
                        <RotateLeftOutlined onClick={onRotateLeft} />
                        <RotateRightOutlined onClick={onRotateRight} />
                        <ZoomOutOutlined
                          disabled={scale === 1}
                          onClick={onZoomOut}
                        />
                        <ZoomInOutlined
                          disabled={scale === 50}
                          onClick={onZoomIn}
                        />
                      </Space>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="gallary-item" bis_skin_checked={1}>
                <Image
                  width="100%"
                  height="100%"
                  className="galley-img"
                  src={gallery3}
                  preview={{
                    toolbarRender: (
                      _,
                      {
                        transform: { scale },
                        actions: {
                          onFlipY,
                          onFlipX,
                          onRotateLeft,
                          onRotateRight,
                          onZoomOut,
                          onZoomIn,
                        },
                      }
                    ) => (
                      <Space size={12} className="toolbar-wrapper">
                        <SwapOutlined rotate={90} onClick={onFlipY} />
                        <SwapOutlined onClick={onFlipX} />
                        <RotateLeftOutlined onClick={onRotateLeft} />
                        <RotateRightOutlined onClick={onRotateRight} />
                        <ZoomOutOutlined
                          disabled={scale === 1}
                          onClick={onZoomOut}
                        />
                        <ZoomInOutlined
                          disabled={scale === 50}
                          onClick={onZoomIn}
                        />
                      </Space>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="gallary-item" bis_skin_checked={1}>
                <Image
                  width="100%"
                  height="100%"
                  className="galley-img"
                  src={gallery4}
                  preview={{
                    toolbarRender: (
                      _,
                      {
                        transform: { scale },
                        actions: {
                          onFlipY,
                          onFlipX,
                          onRotateLeft,
                          onRotateRight,
                          onZoomOut,
                          onZoomIn,
                        },
                      }
                    ) => (
                      <Space size={12} className="toolbar-wrapper">
                        <SwapOutlined rotate={90} onClick={onFlipY} />
                        <SwapOutlined onClick={onFlipX} />
                        <RotateLeftOutlined onClick={onRotateLeft} />
                        <RotateRightOutlined onClick={onRotateRight} />
                        <ZoomOutOutlined
                          disabled={scale === 1}
                          onClick={onZoomOut}
                        />
                        <ZoomInOutlined
                          disabled={scale === 50}
                          onClick={onZoomIn}
                        />
                      </Space>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="gallary-item" bis_skin_checked={1}>
                <Image
                  width="100%"
                  height="100%"
                  className="galley-img"
                  src={gallery5}
                  preview={{
                    toolbarRender: (
                      _,
                      {
                        transform: { scale },
                        actions: {
                          onFlipY,
                          onFlipX,
                          onRotateLeft,
                          onRotateRight,
                          onZoomOut,
                          onZoomIn,
                        },
                      }
                    ) => (
                      <Space size={12} className="toolbar-wrapper">
                        <SwapOutlined rotate={90} onClick={onFlipY} />
                        <SwapOutlined onClick={onFlipX} />
                        <RotateLeftOutlined onClick={onRotateLeft} />
                        <RotateRightOutlined onClick={onRotateRight} />
                        <ZoomOutOutlined
                          disabled={scale === 1}
                          onClick={onZoomOut}
                        />
                        <ZoomInOutlined
                          disabled={scale === 50}
                          onClick={onZoomIn}
                        />
                      </Space>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="gallary-item" bis_skin_checked={1}>
                <Image
                  width="100%"
                  height="100%"
                  className="galley-img"
                  src={gallery6}
                  preview={{
                    toolbarRender: (
                      _,
                      {
                        transform: { scale },
                        actions: {
                          onFlipY,
                          onFlipX,
                          onRotateLeft,
                          onRotateRight,
                          onZoomOut,
                          onZoomIn,
                        },
                      }
                    ) => (
                      <Space size={12} className="toolbar-wrapper">
                        <SwapOutlined rotate={90} onClick={onFlipY} />
                        <SwapOutlined onClick={onFlipX} />
                        <RotateLeftOutlined onClick={onRotateLeft} />
                        <RotateRightOutlined onClick={onRotateRight} />
                        <ZoomOutOutlined
                          disabled={scale === 1}
                          onClick={onZoomOut}
                        />
                        <ZoomInOutlined
                          disabled={scale === 50}
                          onClick={onZoomIn}
                        />
                      </Space>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          {/* <div className="row" bis_skin_checked={1}>
      <div className="col-lg-4 col-md-4" bis_skin_checked={1}>
        <div className="gallary-item" bis_skin_checked={1}>
        <Image
      width="100%"
      height='100%'
      src={gallery1}
      preview={{
        toolbarRender: (
          _,
          {
            transform: { scale },
            actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
          },
        ) => (
          <Space size={12} className="toolbar-wrapper">
            <SwapOutlined rotate={90} onClick={onFlipY} />
            <SwapOutlined onClick={onFlipX} />
            <RotateLeftOutlined onClick={onRotateLeft} />
            <RotateRightOutlined onClick={onRotateRight} />
            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
          </Space>
        ),
      }}
    />
        </div>
        
      </div>
      <div className='col-lg-4 col-md-4' bis_skin_checked={1}>
      <div className="gallary-item" bis_skin_checked={1}>
        <Image
      width="100%"
      height='100%'
      src={gallery2}
      preview={{
        toolbarRender: (
          _,
          {
            transform: { scale },
            actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
          },
        ) => (
          <Space size={12} className="toolbar-wrapper">
            <SwapOutlined rotate={90} onClick={onFlipY} />
            <SwapOutlined onClick={onFlipX} />
            <RotateLeftOutlined onClick={onRotateLeft} />
            <RotateRightOutlined onClick={onRotateRight} />
            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
          </Space>
        ),
      }}
    />
         
        </div>
      </div>
      <div className="col-lg-4 col-md-4" bis_skin_checked={1}>
        <div className="gallary-item" bis_skin_checked={1}>
        <Image
      width="100%"
      height='100%'
      src={gallery3}
      preview={{
        toolbarRender: (
          _,
          {
            transform: { scale },
            actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
          },
        ) => (
          <Space size={12} className="toolbar-wrapper">
            <SwapOutlined rotate={90} onClick={onFlipY} />
            <SwapOutlined onClick={onFlipX} />
            <RotateLeftOutlined onClick={onRotateLeft} />
            <RotateRightOutlined onClick={onRotateRight} />
            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
          </Space>
        ),
      }}
    />
         
        </div>
        <div className="gallary-item" bis_skin_checked={1}>
        <Image
      width="100%"
      height='100%'
      src={gallery4}
      preview={{
        toolbarRender: (
          _,
          {
            transform: { scale },
            actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
          },
        ) => (
          <Space size={12} className="toolbar-wrapper">
            <SwapOutlined rotate={90} onClick={onFlipY} />
            <SwapOutlined onClick={onFlipX} />
            <RotateLeftOutlined onClick={onRotateLeft} />
            <RotateRightOutlined onClick={onRotateRight} />
            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
          </Space>
        ),
      }}
    />
         
        </div>
      </div>
      <div className="col-lg-4 col-md-4" bis_skin_checked={1}>
        <div className="gallary-item" bis_skin_checked={1}>
        <Image
      width="100%"
      height='100%'
      src={gallery5}
      preview={{
        toolbarRender: (
          _,
          {
            transform: { scale },
            actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
          },
        ) => (
          <Space size={12} className="toolbar-wrapper">
            <SwapOutlined rotate={90} onClick={onFlipY} />
            <SwapOutlined onClick={onFlipX} />
            <RotateLeftOutlined onClick={onRotateLeft} />
            <RotateRightOutlined onClick={onRotateRight} />
            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
          </Space>
        ),
      }}
    />
         
        </div>
        <div className="gallary-item" bis_skin_checked={1}>
        <Image
      width="100%"
      height='100%'
      src={gallery6}
      preview={{
        toolbarRender: (
          _,
          {
            transform: { scale },
            actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
          },
        ) => (
          <Space size={12} className="toolbar-wrapper">
            <SwapOutlined rotate={90} onClick={onFlipY} />
            <SwapOutlined onClick={onFlipX} />
            <RotateLeftOutlined onClick={onRotateLeft} />
            <RotateRightOutlined onClick={onRotateRight} />
            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
          </Space>
        ),
      }}
    />
      
        </div>
      </div>
    </div> */}
          <div className="row text-center" bis_skin_checked={1}>
            <div className="package-bottom-btn" bis_skin_checked={1}>
              <Link to="/Gallery">
                <a
                  href="#"
                  className="button-fill-primary"
                  bis_skin_checked={1}
                >
                  View More
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Gallery End */}
      {/* counter start */}
      <Counter />

      {/* counter end */}
      {/* Testimonial Start */}
      <div
        className="testimonial-area testimonial-style-one "
        bis_skin_checked={1}
      >
        <div className="testimonial-shape-group" bis_skin_checked={1} />
        <div className="container position-relative" bis_skin_checked={1}>
          <div className="row align-items-center" bis_skin_checked={1}>
            <div className="col-lg-8" bis_skin_checked={1}>
              <div className="section-head-alpha " bis_skin_checked={1}>
                <h2>What Our Client Say About Us</h2>
                <p>
                Our clients consistently commend our services, praising our dedication, expertise, and reliability. Their testimonials reflect satisfaction and trust
                </p>
              </div>
            </div>
            <div className="col-lg-4" bis_skin_checked={1}>
              <div
                className="slider-arrows text-center d-lg-flex d-none justify-content-end mb-3"
                bis_skin_checked={1}
              >
                {/* <div
            className="testi-prev custom-swiper-prev"
            tabIndex={0}
            role="button"
            aria-label="Previous slide"
            aria-controls="swiper-wrapper-10e2f2d6974baf508"
            bis_skin_checked={1}
          >
            {" "}
            <i className="bi bi-chevron-left" />{" "}
          </div>
          <div
            className="testi-next custom-swiper-next"
            tabIndex={0}
            role="button"
            aria-label="Next slide"
            aria-controls="swiper-wrapper-10e2f2d6974baf508"
            bis_skin_checked={1}
          >
            <i className="bi bi-chevron-right" />
          </div> */}
              </div>
            </div>
          </div>
          <div>
            <TestimonialSlider />
          </div>
        </div>
      </div>

      {/* Testimonial End */}
      {/* Contact Us Start */}
      <div className="more-update-section" style={{}} bis_skin_checked={1}>
        <div className="container" bis_skin_checked={1}>
          <div
            className="row d-flex justify-content-center align-items-center gy-5"
            bis_skin_checked={1}
          >
            <div
              className="col-lg-6 text-lg-start text-center"
              bis_skin_checked={1}
            >
              <div className="more-update-content" bis_skin_checked={1}>
                <h2>Join Us for More Update</h2>
                <p>
                  {/* Duis rutrum nisl urna. Maecenas vel libero faucibus nisi
                  venenatis hendrerit a id lectus. Suspendissendt blandit
                  interdum. */}
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 text-lg-end text-center"
              bis_skin_checked={1}
            >
              <Link to="/Contact">
                <button className="button-fill-primary update-btn">
                  Enquiry now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* contact Us End */}
    </>
  );
};

export default Home;
