import React from 'react'
import { TiWorld } from "react-icons/ti";
import { FaAward } from "react-icons/fa";
import { FaMap } from "react-icons/fa";
import { MdOutlineHolidayVillage } from "react-icons/md";
import { MdOutlineEventAvailable } from "react-icons/md";

const Counter = () => {
  return (
    <>
         <div
        className="newslatter-wrapper mt-120 achievement-banner"
        bis_skin_checked={1}
      >
        <div className="container" bis_skin_checked={1}>
          <div className="row align-items-center" bis_skin_checked={1}>
           
            {/* <div className="col-lg-6 " bis_skin_checked={1}> */}
            <div className="achievement-counter-side" bis_skin_checked={1}>
              <div
                className="section-head-alpha section-padding-15 text-center mx-auto"
                bis_skin_checked={1}
              >
                <h2 className="text-white"> Achievement </h2>
                {/* <p>
            Duis rutrum nisl urna. Maecenas vel libero faucibus nisi venenatis
            hendrerit a id lectus. Suspendissendt blandit interdum. Sed
            pellentesque at nunc eget consectetur.
          </p> */}
              </div>
              <div className="row g-4" bis_skin_checked={1}>
                <div className="col-lg-3  col-md-3" bis_skin_checked={1}>
                  <div
                    className="achievement-box-style-one"
                    bis_skin_checked={1}
                  >
                    <div className="achievement-icon" bis_skin_checked={1}>
                      <TiWorld className="counter-icons text-white" />

                      {/* <img src="assets/images/icons/counter-icon2.svg" alt="" /> */}
                    </div>
                    <div
                      className="achievement-box-content"
                      bis_skin_checked={1}
                    >
                      <h2>30+</h2>
                      <h4>Location</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3  col-md-3" bis_skin_checked={1}>
                  <div
                    className="achievement-box-style-one"
                    bis_skin_checked={1}
                  >
                    <div className="achievement-icon" bis_skin_checked={1}>
                      <FaMap className="counter-icons text-white" />

                      {/* <img src="assets/images/icons/counter-icon3.svg" alt="" /> */}
                    </div>
                    <div
                      className="achievement-box-content"
                      bis_skin_checked={1}
                    >
                      <h2>50+</h2>
                      <h4>New Destinations</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3  col-md-3" bis_skin_checked={1}>
                  <div
                    className="achievement-box-style-one"
                    bis_skin_checked={1}
                  >
                    <div className="achievement-icon" bis_skin_checked={1}>
                      <FaAward className="counter-icons text-white" />

                      {/* <img src="assets/images/icons/counter-icon1.svg" alt="" /> */}
                    </div>
                    <div
                      className="achievement-box-content"
                      bis_skin_checked={1}
                    >
                      <h2>05+</h2>
                      <h4>Years Experience</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3  col-md-3" bis_skin_checked={1}>
                  <div
                    className="achievement-box-style-one"
                    bis_skin_checked={1}
                  >
                    <div className="achievement-icon" bis_skin_checked={1}>
                      <MdOutlineEventAvailable className="counter-icons text-white" />
                      {/* <img src="assets/images/icons/counter-icon4.svg" alt="" /> */}
                    </div>
                    <div
                      className="achievement-box-content"
                      bis_skin_checked={1}
                    >
                      <h2>150+</h2>
                      <h4> Happy Clients</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Counter