import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import propertyData from "../Components/PropertyData";
import GalleyCard from "./GalleyCard";
const TabGallery = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  // const
  const [selectedProperty, setSelectedProperty] = useState("");
  const [results, setResults] = useState([]);
  useEffect(() => {
    onChange("1");
  }, []);

  const onChange = (key) => {
    // Find the selected item based on the key
    const selectedItem = items.find((item) => item.key === key);

    // Log the label of the selected item
    console.log(selectedItem.label);
    setSelectedProperty(selectedItem.label);
    const name = propertyData.find(
      (item) => item.propertyName === selectedItem.label
    );

    // Assuming propertyImage is nested inside arrays and objects
    console.log(name);
    const property = name?.hotelList;

    // console.log(property);
    // Combine propertyImage arrays from both objects into a new array
    //       const baseImageUrl = `${window.location.origin}`; // replace with your actual base URL
    // const combinedImages = property.reduce((acc, obj) => {
    //   // Concatenate base URL with each image path and then concatenate to the accumulator
    //   return acc.concat(obj.propertyImage.map(image => baseImageUrl + image));
    // }, []);

    // console.log(combinedImages);
    // setResults(combinedImages);
    const combinedImages = property.reduce((acc, obj) => {
      return acc.concat(obj.propertyImage);
    }, []);
    console.log(property);
    // console.log(combinedImages);
    setResults(combinedImages);
  };

  //   const a = property.forEach((v)=>{return v})
  //   console.log(a);
  //   var result = property.map(value =>
  //     value
  //     // .propertyImage.map(child => (child))
  //   )
  // const result = property.map((v,i)=>{ v.propertyImage.map((v,i)=>{ v})})
  //   console.log(result);

  const items = [
    {
      key: "1",
      label: "Hotel",
      //   children: 'Content of Tab Pane 1',
    },
    {
      key: "2",
      label: "Resort",
      //   children: 'Content of Tab Pane 2',
    },
    {
      key: "3",
      label: "Villa",
      //   children: 'Content of Tab Pane 3',
    },
    {
      key: "4",
      label: "Banquet",
      // children: 'Content of Tab Pane 3',
    },
  ];
  return (
    <>
      <Tabs defaultActiveKey="1" onChange={onChange}>
        {items.map((item) => (
          <Tabs.TabPane key={item.key} tab={item.label}>
            {item.children}
          </Tabs.TabPane>
        ))}
      </Tabs>
      <GalleyCard images={results} />
    </>
  );
};

export default TabGallery;
