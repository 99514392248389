import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

const VillaSlider = ({images}) => {
  return (
    <>
     <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
    {images.map((v,i)=>{
        return<>
        <SwiperSlide>
            <div>
                <img
                  src={v}
                  style={{ width: "100%", height: "100%", maxWidth: "800px", maxHeight: "400px" }}
                  loading='lazy'
                  alt='villa rooms'
                />
            </div>
        
        </SwiperSlide>
        </>
    })}
      </Swiper>
    </>
  )
}

export default VillaSlider