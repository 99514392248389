import React, { useState, useCallback,useLayoutEffect } from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { TiEyeOutline } from "react-icons/ti";
// import Gallery from "react-photo-gallery";
// import Carousel, { Modal, ModalGateway } from "react-images";
// import  ProjectImages from "../Components/ServicesData";
import "../Css/Gallery.css";
import MetaTags from 'react-meta-tags';
import TabGallery from "./TabGallery";
// import cv1 from "../Img/gallery/New folder/cv(0)-16.webp";
// import cv2 from "../Img/gallery/New folder/cv(2).webp";
// import cv29 from "../Img/gallery/New folder/cv(29).jpg";
// import cv3 from "../Img/gallery/New folder/cv(3).jpeg";
// import cv30 from "../Img/gallery/New folder/cv(30).jpg";
// import cv31 from "../Img/gallery/New folder/cv(31).jpg";
// import cv32 from "../Img/gallery/New folder/cv(32).jpg";
// import cv33 from "../Img/gallery/New folder/cv(33).jpg";
// import cv34 from "../Img/gallery/New folder/cv(34).webp";
// import cv35 from "../Img/gallery/New folder/cv(35).webp";
// import cv36 from "../Img/gallery/New folder/cv(36).webp";
// import cv44 from "../Img/gallery/New folder/cv(44).webp";
// import cv45 from "../Img/gallery/New folder/cv(45).webp";
// import cv46 from "../Img/gallery/New folder/cv(46).webp";
// import cv48 from "../Img/gallery/New folder/cv(48).webp";
// import cv49 from "../Img/gallery/New folder/cv(49).webp";
// import cv50 from "../Img/gallery/New folder/cv(50).webp";
// import cv51 from "../Img/gallery/New folder/cv(51).webp";
// import cv28 from "../Img/gallery/New folder/cv(28).jpg";
// import cv9 from "../Img/gallery/gallery9.jpg";
// import cv22 from "../Img/gallery/gallery2.webp";
// import cv1 from "../Img/gallery/comprees-gallery/cv (1).webp";
// import cv2 from "../Img/gallery/comprees-gallery/cv (2).webp";
// import cv3 from "../Img/gallery/comprees-gallery/cv (3).webp";
// import cv4 from "../Img/gallery/comprees-gallery/cv (4).webp";
// import cv5 from "../Img/gallery/comprees-gallery/cv (5).webp";
// import cv6 from "../Img/gallery/comprees-gallery/cv (6).webp";
// import cv7 from "../Img/gallery/comprees-gallery/cv (7).webp";
// import cv8 from "../Img/gallery/comprees-gallery/cv (8).webp";
// import cv9 from "../Img/gallery/comprees-gallery/cv (9).webp";
// import cv10 from "../Img/gallery/comprees-gallery/cv (10).webp";
// import cv11 from "../Img/gallery/comprees-gallery/cv (11).webp";
// import cv12 from "../Img/gallery/comprees-gallery/cv (12).webp";
// import cv13 from "../Img/gallery/comprees-gallery/cv (13).webp";
// import cv14 from "../Img/gallery/comprees-gallery/cv (14).webp";
// import cv15 from "../Img/gallery/comprees-gallery/cv (15).webp";
// import cv16 from "../Img/gallery/comprees-gallery/cv (16).webp";
// import cv17 from "../Img/gallery/comprees-gallery/cv (17).webp";
// import cv18 from "../Img/gallery/comprees-gallery/cv (18).webp";
// import cv19 from "../Img/gallery/comprees-gallery/cv (19).webp";
// import cv20 from "../Img/gallery/comprees-gallery/cv (20).webp";
// import cv21 from "../Img/gallery/comprees-gallery/cv (21).webp";
// import cv22 from "../Img/gallery/comprees-gallery/cv (22).webp";
// import cv23 from "../Img/gallery/comprees-gallery/cv (23).webp";
const Gallerys = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
    // ========== Smile Gallery ========
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const images = [];

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };



  ////end
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  // const openLightbox = useCallback((event, { index }) => {
  //   setCurrentImage(index);
  //   setViewerIsOpen(true);
  // }, []);

  // const closeLightbox = () => {
  //   setCurrentImage(0);
  //   setViewerIsOpen(false);
  // };

  return (
    <>
   
      {/* ========= Gallery Banner ====== */}
      {/* <div className="GalleryBanner">
        <div className="container">
          <h1 className="GalleryBanner-title">Gallery</h1>
        </div>
      </div> */}
      <div className="breadcrumb breadcrumb-style-one"  bis_skin_checked={1}>
  <div className="container" bis_skin_checked={1}>
    <div className="col-lg-12 text-center" bis_skin_checked={1}>
      <h2 className="breadcrumb-title">Gallery</h2>
      <ul className="d-flex justify-content-center breadcrumb-items">
        <li className="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        <li className="breadcrumb-item active">Gallery</li>
      </ul>
    </div>
  </div>
    </div>
      <section className="smile-section">
        <div className="container">
             <div className="sec-title centered">
      <h2 className="text-center my-5">Photo Gallery</h2>
    </div>
    <TabGallery />
   
        </div>
      </section> 
    </>
  );
};

export default Gallerys;
