import React, { useEffect } from 'react';
import "../Css/RoomCard.css";
import { FaStar } from "react-icons/fa6";
import { LuScale3D } from "react-icons/lu";
import { IoWifi } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa6";
import { FaBed } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const CardsRoom = ({detail, slug, name}) => {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
    return (
        <>
<section className='Rooms-Page-Section-Card-con '>
                <div className='container'>
                    <div className='Rooms-Page-Section-Card shadow-sm p-3 mb-5 bg-white rounded'>
                        <div className='row'>
                                <div className='Room-Page-Section-card-Image'>
                                    <img style={{height:"100%", width:"100%" ,maxHeight:"300px", minWidth:"300px"}} src={detail.propertyImage[0]} alt='' />
                                </div>
                                <div className='Room-Page-Section-Card-Details-con'>

                                    <div className='Room-Page-Card-Name-And-Reviews-Card'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='Room-Page-card-Name-And-Star-con'>
                                                    <h2> {detail.propertyName} </h2>
                                                    <div className='Room-Page-card-Star-con'>
                                                        <div className='Room-Oage-card-Star starcolor'>
                                                            <FaStar />
                                                        </div>
                                                        <div className='Room-Oage-card-Star starcolor'>
                                                            <FaStar />
                                                        </div>
                                                        <div className='Room-Oage-card-Star starcolor'>
                                                            <FaStar />
                                                        </div>
                                                        <div className='Room-Oage-card-Star starcolor'>
                                                            <FaStar />
                                                        </div>
                                                        <div className='Room-Oage-card-Star'>
                                                            <FaStar />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='Rooms-page-Card-reviews-Con'>
                                                    <div className='Rooms-page-Card-reviews-Number'>
                                                        <h3>Very Good</h3>
                                                        <h4> review</h4>
                                                    </div>
                                                    <div className='Rooms-page-Card-reviews-Number-Rate'>
                                                        <h5>{name}</h5>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                    <div className='Rooms-page-card-RoomsDetails'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='Rooms-page-card-RoomsDetails-Icon-And-Name-con'>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                <IoWifi />
                                                                <h6>inclusive wifi</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                <FaRegUser />
                                                                <h6>Adult 3</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                <LuScale3D />
                                                                <h6>{name}</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                <FaBed />
                                                                <h6>Queen Bed</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='property-btn'>
                                            <div className=''>
                                              
                                                <div className='Room-Page-card-book-Btn'>
                                                <Link to={slug+detail.slugs}>
                                                    <button className='text-muted'>
                                                        More Details
                                                    </button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className=''>
                                               
                                                <div className='Room-Page-card-book-Btn'>
                                                <Link to={slug+detail.slugs}>
                                                    <button className='text-muted'>
                                                        Enquiry Now
                                                    </button>
                                                    </Link>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                           

                        </div>
                    </div>
                </div>
            </section >
         

        </>
    )
}

export default CardsRoom