import React, { useLayoutEffect } from "react";
import "../Css/Footer.css";
import { Link } from "react-router-dom";
// import Logo from "../images/Green Farm.png";
import { CiFacebook } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdCall } from "react-icons/io";
import logo from "../Asserts/2-removebg-preview.png";

const Footer = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-cta pt-5 pb-5">
            <div className="row">
              <div className="col-lg-5 col-md-6  mb-30">
                <div className="single-cta">
                  <i className="fa fa-map-marker" ><FaLocationDot /></i>
                  <div className="cta-text pt-2">
                    <h4>Find us</h4>
                    <span>BN 9 CTS N 184vishal construction khandala 410402</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="single-cta">
                  <i className="fa fa-phone" > <IoMdCall /></i>
                  <div className="cta-text pt-2">
                    <h4>Call us</h4>
                    <span>8698899429 / 92098 61778 /  74986 09280 </span>
                  </div>  
                </div>
              </div>
              <div className="col-lg-3 col-12 mb-30">
                <div className="single-cta">
                  <i className="fa fa-envelope-open" ><IoMdMail /></i>
                  <div className="cta-text pt-2">
                    <h4>Mail us</h4>
                    <span>
                    travelbee2729@gmail.com
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <a href="index.html">
                    </a>
                  </div>
                  <div className="footer-text">
                  <img src={logo} style={{ width:"150px",height:"150px"}} alt="Logo" />
                  
                  </div>
                  <div className="footer-widget">

                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Useful Links</h3>
                  </div>
                  <ul className="m-0 p-0">
                    <li>
                      <Link to="/">Home</Link>
                    </li>

                    <li>
                      <Link to="/About">About Us</Link>
                    </li>

                    <li>
                      <Link to="/Events">Event</Link>
                    </li>
                    {/* <li>
                      <Link to="/best-agro-tourism-resort-neral">Property</Link>
                    </li> */}

                    <li>
                      <Link to="/Gallery">Gallery</Link>
                    </li>
                    <li>
                      <Link to="/Contact">Contact Us </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Address</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <p>
                    BN 9 CTS N 184vishal construction khandala 410402

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                <div className="copyright-text">
                  <p>
                    Copyright © 2024, All Right Reserved <br />
                    <a href="http://travelbeez.in/">Travel beez</a>
                  </p>
                  <p>
                    Developed By &nbsp;
                    <a href="https://skdm.in/">
                      Shree Krishna Digital Marketing
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="floattingButton">
        <ul>
          <li>
            <a href="https://wa.me/7498609280" target="_blank">
              <i className="fa fa-whatsapp my-float">
                {" "}
                <FaWhatsapp />{" "}
              </i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/tra_velbeez/" target="_blank">
              <i className="fa fa-instagram my-float">
                {" "}
                <FaInstagram />
              </i>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/travelbeeez/" target="_blank">
              <i className="fa fa-facebook my-float">
                <CiFacebook />{" "}
              </i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
